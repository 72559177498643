import { Provider } from 'inversify-react';

import InfrastructureProvider from 'infrastructure/contexts/InfrastructureProvider';
import OIDCProvider from 'infrastructure/keycloak/OIDCProvider';

import container from './container';
import Router from './routes/Router';
import { ThemeProvider } from './modules/dashboard/components/ThemeProvider';
import SnackbarProvider from './contexts/SnackbarProvider';

export default function App() {
  return (
    <Provider container={container}>
      <OIDCProvider>
        <InfrastructureProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <Router />
            </SnackbarProvider>
          </ThemeProvider>
        </InfrastructureProvider>
      </OIDCProvider>
    </Provider>
  );
}
