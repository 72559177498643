import { FC } from 'react';
import { Alert as MUIAlert, AlertProps as MUIAlertProps, SnackbarProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import IconButton from 'targets/web/components/IconButton';

export interface AlertProps extends MUIAlertProps {
  message: SnackbarProps['message'];
  severity?: MUIAlertProps['severity'];
  variant?: MUIAlertProps['variant'];
  hideCloseButton?: boolean;
}

const Alert: FC<AlertProps> = ({
  severity,
  variant,
  message,
  action,
  hideCloseButton,
  onClose,
}) => (
  <MUIAlert
    severity={severity}
    variant={variant}
    action={
      <>
        {action}
        {!hideCloseButton && (
          <IconButton
            variant="text"
            color="inherit"
            size="small"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </>
    }
    onClose={onClose}
  >
    {message}
  </MUIAlert>
);

export default Alert;
