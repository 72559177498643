import * as React from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { GlobalStyles } from '@mui/material';

import NotistackSnackbar from 'targets/web/components/NotistackSnackbar';

const SnackbarProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <GlobalStyles styles={{ '.notistack-root-snackbar': { minWidth: 'inherit' } }} />
    <NotistackSnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      Components={{
        error: NotistackSnackbar,
        success: NotistackSnackbar,
        info: NotistackSnackbar,
        warning: NotistackSnackbar,
      }}
      classes={{
        root: 'notistack-root-snackbar',
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  </>
);

export default SnackbarProvider;
