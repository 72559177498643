import { useQuery } from '@tanstack/react-query';

import { AircraftRepository, SearchAircraftParams } from 'domain/repositories/IAircraftRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchAircraft = (params: SearchAircraftParams) => {
  const { searchAircraft } = useHookInjection<AircraftRepository>(AircraftRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['AIRCRAFT', params] as const,
    queryFn: ({ queryKey }) => searchAircraft(queryKey[1]),
    initialData: { items: [], total: 0 },
  });

  return {
    ...data,
    isLoading: isLoading || isFetching,
  };
};
