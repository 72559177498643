import { useAuth } from 'react-oidc-context';
import { useCallback, useMemo } from 'react';
import contract from 'api/infrastructure/contract/jobs.route';
import { Damage } from 'api/domain/entities/documents/PreWorkQuestionnaire';
import { initClient } from '@ts-rest/core';
import { parseISO } from 'date-fns';

import { JobRepository, SearchJobsParams } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';

const transformParams = ({ sortBy, ...params }: SearchJobsParams) => {
  return {
    ...params,
    sortBy: sortBy === 'customer' || sortBy === 'station' ? `${sortBy}.name` : sortBy,
  };
};

type RepositoryFunctions = ReturnType<JobRepository>;

export const useTsRestJobRepository: JobRepository = () => {
  const auth = useAuth();

  const client = useMemo(() => {
    return initClient(contract, {
      baseUrl: import.meta.env.VITE_BACKEND_API_BASE_URL,
      baseHeaders: {
        Authorization: `Bearer ${auth.user?.access_token}`,
      },
    });
  }, [auth.user?.access_token]);

  const searchJobs: RepositoryFunctions['searchJobs'] = useCallback(
    async (params) => {
      const res = await client.searchJobs({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return {
          ...res.body,
          items: res.body.items.map((job) => ({
            ...job,
            // @ts-expect-error TS2345
            due: parseISO(job.due),
            // @ts-expect-error TS2345
            scheduledStart: parseISO(job.scheduledStart),
            // @ts-expect-error TS2345
            eta: parseISO(job.eta),
            // @ts-expect-error TS2345
            etd: parseISO(job.etd),
          })),
        };
      }

      throw res;
    },
    [client],
  );

  const fetchJob: RepositoryFunctions['fetchJob'] = useCallback(
    async (params) => {
      if (!params.id) throw new Error('Missing job id');
      const res = await client.getJob({
        params: { jobId: ID(params.id) },
      });
      if (res.status === 200) {
        return {
          ...res.body,
          createdAt: new Date(res.body.createdAt),
          eta: new Date(res.body.eta),
          etd: new Date(res.body.etd),
          due: new Date(res.body.due),
          scheduledStart: new Date(res.body.scheduledStart),
        };
      }
      throw res;
    },
    [client],
  );

  const getCountByStatus: RepositoryFunctions['getCountByStatus'] = useCallback(
    async (params) => {
      const res = await client.getCountJobsStatus({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const createJob: RepositoryFunctions['createJob'] = useCallback(
    async (params) => {
      const res = await client.createJob({
        body: {
          ...params,
          serviceTypes: Array.from(params.serviceTypes) as unknown as Set<ID>,
        },
      });
      if (res.status === 201) {
        return res.body;
      }
      throw res;
    },
    [client],
  );

  const submitPreWorkQuestionnaire: RepositoryFunctions['submitPreWorkQuestionnaire'] = useCallback(
    async (params) => {
      const res = await client.submitPreWorkQuestionnaire({
        body: {
          ...params,
          damagesFound: Array.from(params.damagesFound) as unknown as Set<Damage>,
        },
        params: { jobId: params.jobId },
      });
      if (res.status === 204) {
        return;
      }
      throw res;
    },
    [client],
  );

  return {
    searchJobs,
    fetchJob,
    getCountByStatus,
    createJob,
    submitPreWorkQuestionnaire,
  };
};
