import { FC, useCallback, useEffect } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { SortingDirection } from 'common/types/SortingOptions';

import { useCaseSearchJobs } from 'application/jobs/useCases/useCaseSearchJobs';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import { Button, VirtualizedGrid } from 'targets/web/components';
import { FiltersContainer, JobLogCard } from 'targets/web/modules/jobs/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { useJobViewFilters } from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import { JobViewTabs } from 'targets/web/modules/jobs/components/JobViewTabs';
import { JobsTable } from 'targets/web/modules/jobs/components/JobsTable';

export const JobsView: FC = () => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('jobs.jobs_view');
  const theme = useTheme();

  const { filters, setFilters, viewType } = useJobViewFilters();
  const { allPages: jobs, fetchNextPage, hasNextPage, isFetching } = useCaseSearchJobs(filters);

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  useEffect(() => {
    if (viewType === 'grid-view') {
      setFilters((current) => ({
        ...current,
        sortBy: 'status',
        direction: SortingDirection.Ascending,
      }));
    } else {
      setFilters((current) => ({
        ...current,
        sortBy: 'due',
        direction: SortingDirection.Descending,
      }));
    }
  }, [setFilters, viewType]);

  return (
    <>
      <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title={t('title')} />

        <Button startIcon={<AddIcon />} onClick={() => navigate(jobsRoutes.internalRoutes.new)}>
          {t('add_job_button')}
        </Button>
      </Stack>

      <FiltersContainer
        onFiltersChange={(values) => {
          setFilters((current) => ({ ...current, ...values }));
        }}
      />

      <Stack
        gap={4}
        sx={{
          width: 1,
          height: 1,
          marginBottom: viewType === 'grid-view' ? theme.spacing(-4) : undefined,
        }}
      >
        <JobViewTabs />

        {viewType === 'grid-view' ? (
          <VirtualizedGrid
            style={{
              minHeight: 'calc(100vh - 168px)',
            }}
            data={jobs}
            spacing={4}
            minColumnWidth={280}
            itemContent={(_index, job) => <JobLogCard {...job} />}
            components={{ Footer: () => <Box sx={{ width: 1, height: theme.spacing(4) }} /> }}
            endReached={loadMore}
            overscan={200}
          />
        ) : (
          <Stack sx={{ maxHeight: 'calc(100vh - 168px)' }}>
            <JobsTable />
          </Stack>
        )}
      </Stack>
    </>
  );
};
