import { IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';

import { JobDetailsForm } from '../components';
import { JobDetailsData } from '../components/JobDetailsForm/JobDetailsForm';

export const JobDetailsView: FC = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();

  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.job_details');
  const { items: stations } = useCaseSearchStation({});
  const { data } = useCaseGetJobDetails({ id: jobId });

  if (!data) return null;
  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate(-1)} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
          <Breadcrumbs
            linkComponent={Link}
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title', { job: jobId })} />
        </Stack>
      </Stack>
      <JobDetailsForm
        initialValues={
          {
            station: data.stationId,
            customer: data.customerId,
            aircraft: data.aircraftId,
            eta: data.eta.toISOString(),
            scheduled: data.scheduledStart.toISOString(),
            deliverBy: data.etd.toISOString(),
            notes: data.notes,
            services: data.services.map((service) => service.code),
          } as JobDetailsData
        }
        disabled
        stations={stations}
        customers={[{ displayName: data.customer.name, id: data.customerId }]}
        aircrafts={[
          {
            name: data.aircraft.code,
            id: data.aircraftId,
            type: data.aircraft.typeId,
            regNumber: data.aircraft.code,
            serialNumber: data.aircraft.serialNumber ?? '',
          },
        ]}
        services={data.services.map((service) => ({
          id: service.code,
          name: service.name,
        }))}
      />
    </>
  );
};
