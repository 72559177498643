import { FC, ReactNode } from 'react';
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Link, { LinkProps } from 'targets/web/components/Link';

export interface Breadcrumb {
  label: string;
  url?: string;
}

export interface BreadcrumbsProps extends Omit<MuiBreadcrumbsProps, 'onClick'> {
  onClick?: (breadcrumb: Breadcrumb) => void;
  items?: Breadcrumb[];
  linkComponent?: LinkProps['component'];
  children?: ReactNode;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  onClick,
  items,
  children,
  linkComponent,
  ...props
}) => {
  const handleClick = (breadcrumb: Breadcrumb) => onClick && onClick(breadcrumb);

  const itemsLinks = items?.map((breadcrumb, index) =>
    breadcrumb.url ? (
      <Link
        key={index}
        variant="body2"
        color="text.secondary"
        underline="none"
        component={linkComponent}
        onClick={() => handleClick(breadcrumb)}
      >
        {breadcrumb.label}
      </Link>
    ) : (
      <Typography key={index} color="text.secondary" variant="body2">
        {breadcrumb.label}
      </Typography>
    ),
  );

  const breadcrumbs = children || itemsLinks;

  return (
    <MuiBreadcrumbs
      {...props}
      separator={<ChevronRightIcon color="action" fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
