import { useAuth } from 'react-oidc-context';

import { IAuthContextRepositoryHook } from 'domain/repositories/IAuthContextRepositoryHook';
import { AuthContext } from 'domain/entities/AuthContext';

const useOIDCAuthContextRepository: IAuthContextRepositoryHook = () => {
  const auth = useAuth();
  return {
    useAuthContext(): AuthContext | undefined {
      if (!auth.isAuthenticated || !auth.user) {
        return undefined;
      }

      return {
        accessToken: auth.user.access_token,
        refreshToken: auth.user.refresh_token || '',
        roles: auth.user.scopes.map((s) => ({ role: s })),
      };
    },

    setAuthContext() {
      throw new Error("OIDC can't set auth context manually");
    },
  };
};

export default useOIDCAuthContextRepository;
