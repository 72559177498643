import { FC } from 'react';
import { useNavigate } from 'react-router';
import { IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ID } from 'domain/types/ID';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import { useCaseSearchCustomer } from 'application/customers/useCases/useCaseSearchCustomer';
import { useCaseSearchAircraft } from 'application/aircraft/useCases/useCaseSearchAircraft';
import { useCaseCreateJob } from 'application/services/useCases/useCaseCreateJob';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { JobDetailsData } from 'targets/web/modules/jobs/components/JobDetailsForm/JobDetailsForm';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { JobDetailsForm } from 'targets/web/modules/jobs/components';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import ReactRouterLink from 'targets/web/components/ReactRouterLink';

export const NewJobView: FC = () => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('jobs.jobs_view');
  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const { items: stations } = useCaseSearchStation({});
  const { items: customers } = useCaseSearchCustomer({});
  const { items: aircrafts } = useCaseSearchAircraft({});
  const { serviceTypes: services } = useCaseGetServiceTypes();
  const { mutateAsync: createJob } = useCaseCreateJob();

  const handleSubmit = async (values: JobDetailsData) => {
    const aircraft = aircrafts.find(({ id }) => id === values.aircraft);
    if (!aircraft) return;
    await createJob({
      stationId: ID(values.station),
      customerId: ID(values.customer),
      name: aircraft.code,
      aircraftId: ID(values.aircraft),
      due: values.deliverBy ? new Date(values.deliverBy) : new Date(),
      scheduledStart: values.scheduled ? new Date(values.scheduled) : new Date(),
      eta: values.eta ? new Date(values.eta) : new Date(),
      etd: values.etd ? new Date(values.etd) : new Date(),
      aircraftSerialNo: '',
      serviceTypes: new Set<ID>(values.services.map((service) => ID(service))),
    });
    navigate(jobsRoutes.jobs);
  };

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate('../')} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
          <Breadcrumbs
            linkComponent={ReactRouterLink}
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('new_job'),
                url: jobsRoutes.internalRoutes.new,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
      </Stack>

      <JobDetailsForm
        initialValues={
          {
            station: '',
            customer: '',
            aircraft: '',
            eta: '',
            scheduled: '',
            deliverBy: '',
            notes: '',
            services: [],
          } as JobDetailsData
        }
        onSubmit={handleSubmit}
        onCancel={() => navigate(jobsRoutes.jobs)}
        disabled={false}
        stations={stations}
        customers={customers.map(({ id, name }) => ({ displayName: name, id }))}
        aircrafts={aircrafts.map(({ code, id, serialNumber, type }) => ({
          name: code,
          id,
          type: type.name,
          regNumber: code,
          serialNumber: serialNumber ?? '',
        }))}
        services={services.map(({ code, name }) => ({ id: code, name }))}
      />
    </>
  );
};
