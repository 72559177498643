import { ContainerModule } from 'inversify';

import { AuthContextRepository } from 'domain/repositories/IAuthContextRepositoryHook';
import { LogoutAdapter } from 'application/auth/useCases/useCaseLogout';

import useOIDCAuthContextRepository from './OIDCAuthContextRepository';
import OIDCLogoutAdapter from './OIDCLogoutAdapter';

export default new ContainerModule((bind) => {
  bind(AuthContextRepository).toConstantValue(useOIDCAuthContextRepository);
  bind(LogoutAdapter).to(OIDCLogoutAdapter);
});
