import { Typography } from '@mui/material';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import z from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Select } from 'targets/web/components';

import { Container } from './common';

export const PrimaryJobDetailsSchema = z.object({
  station: z.string(),
  customer: z.string(),
});

export type PrimaryJobDetailsData = z.infer<typeof PrimaryJobDetailsSchema>;

export interface PrimaryJobDetailsProps {
  disabled: boolean;
  customers: {
    displayName: string;
    id: string;
  }[];
  stations: {
    name: string;
    id: string;
  }[];
}

export const PrimaryJobDetails: FC<PrimaryJobDetailsProps> = ({
  disabled,
  customers,
  stations,
}) => {
  const { values, setFieldValue } = useFormikContext<PrimaryJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');
  return (
    <Container spacing={4}>
      <Typography variant="h5">{t('title')}</Typography>

      <Select
        label={t('station_label')}
        name="station"
        value={values.station}
        fullWidth
        disabled={disabled}
        onChange={(e) => setFieldValue('station', e.target.value)}
        items={stations.map(({ name, id }) => ({ label: name, value: id }))}
      />
      <Select
        label={t('customer_label')}
        name="customer"
        value={values.customer}
        fullWidth
        disabled={disabled}
        onChange={(e) => setFieldValue('customer', e.target.value)}
        items={customers.map(({ displayName, id }) => ({ label: displayName, value: id }))}
      />
    </Container>
  );
};
