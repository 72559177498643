import { DefaultError, InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';
import { useMemo } from 'react';

import { JobRepository, SearchJobsParams } from 'domain/repositories/IJobRepository';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Job } from 'domain/entities/Job';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchJobs = (params: Omit<SearchJobsParams, 'offset'>) => {
  const { searchJobs } = useHookInjection<JobRepository>(JobRepository);

  const initialPageParam: Pagination = useMemo(
    () => ({
      limit: params.limit ?? 10,
      offset: 0,
    }),
    [params.limit],
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery<
      PaginatedResult<Job>,
      DefaultError,
      InfiniteData<PaginatedResult<Job>>,
      [string, typeof params],
      Pagination
    >({
      queryKey: ['JOBS', params],
      queryFn: ({ queryKey, pageParam }) => searchJobs({ ...pageParam, ...queryKey[1] }),
      getNextPageParam: (lastPage, _pages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit < lastPage.total) {
          return {
            ...lastPageParam,
            offset: lastPageParam.offset + lastPageParam.limit,
          };
        }

        return null;
      },
      initialPageParam,
      maxPages: 100,
      initialData: {
        pages: [{ items: [], total: 0 }],
        pageParams: [initialPageParam],
      },
    });

  const allPages = useMemo(() => data?.pages.map((page) => page.items).flat(1), [data?.pages]);

  return {
    ...data,
    allPages,
    total: data?.pages[0]?.total ?? [],
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
