import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PageNotFoundView from 'targets/web/modules/dashboard/views/PageNotFound.view';

import AuthorizedRoutes from './AuthorizedRoutes';
import PublicRoutes from './PublicRoutes';

const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      {PublicRoutes}
      {AuthorizedRoutes}
      <Route path="*" element={<PageNotFoundView />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
