import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container, ServiceDetailsField } from './common';

export interface NotesServiceDetailsPreviewData {
  notes?: string;
}

export interface NotesServiceDetailsPreviewProps {
  data: NotesServiceDetailsPreviewData;
}

export const NotesServiceDetailsPreview: FC<NotesServiceDetailsPreviewProps> = ({ data }) => {
  const t = useTranslationPrefix('jobs.service_details.notes');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h5">{t('title')}</Typography>
      <Stack gap={4}>
        <ServiceDetailsField
          title={t('notes_label')}
          value={data.notes ?? t('no_notes')}
          disabled={!data.notes}
        />
      </Stack>
    </Container>
  );
};
