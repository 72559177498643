import { FC } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import Button from 'targets/web/components/Button';

export const ScheduleView: FC = () => {
  return (
    <Stack spacing={6} flex={1}>
      <PageTitle title={'Schedule'}>
        <Button startIcon={<AddIcon />}>Create Request</Button>
      </PageTitle>
    </Stack>
  );
};
