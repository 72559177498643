import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete } from 'targets/web/components';

export const StationAutocomplete: FC<{ onChange: (id: ID | undefined) => void }> = ({
  onChange,
}) => {
  const t = useTranslationPrefix('components.filters_container');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items, isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const stations = useMemo(
    () => [{ id: ID('all'), name: 'All' } as Entity<Station>].concat(items),
    [items],
  );

  const handleInputChange = useCallback(
    debounce((event: SyntheticEvent, newInputValue: string) => {
      if (event?.type !== 'change' && newInputValue) {
        return;
      }

      setStationPhrase(newInputValue);
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Station>, false, false, false>
      label={t('station_label')}
      loading={isLoading}
      options={stations}
      defaultValue={stations[0]}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={(_, value) => {
        onChange(value && value.id !== 'all' ? ID(value.id) : undefined);
      }}
    />
  );
};
