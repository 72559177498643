import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container, ServiceDetailsField } from './common';

export type WaitTimeServiceDetailsPreviewData = {
  time: string;
  reason: string;
  notes: string | null;
};

export interface WaitTimeServiceDetailsPreviewProps {
  data: WaitTimeServiceDetailsPreviewData;
}

export const WaitTimeServiceDetailsPreview: FC<WaitTimeServiceDetailsPreviewProps> = ({ data }) => {
  const t = useTranslationPrefix('jobs.service_details.wait_time');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h5">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('time_label')} value={data.time} />
            <ServiceDetailsField title={t('reason_label')} value={data.reason} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField
              title={t('notes_label')}
              disabled={!data.notes}
              value={data.notes ?? t('no_notes')}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
