import { FC, isValidElement, ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';

import Button, { ButtonProps } from 'targets/web/components/Button';

interface ActionButtonProps extends ButtonProps {
  text: string;
}

export interface ModalProps extends Omit<DialogProps, 'content'> {
  /**
   * Modal title.
   */
  title?: string;
  /**
   * Modal description.
   */
  description?: ReactNode;
  /**
   * Modal content. Can be plain text or a React node.
   */
  content?: ReactNode;
  /**
   * Modal actions. It accepts an object with _primary_ and _secondary_ `Button` props or a React node.
   */
  actions?: { primary?: ActionButtonProps; secondary?: ActionButtonProps } | ReactNode;
  /**
   * Width of the modal. Defaults to `480` pixels.
   */
  width?: number | string;
  /**
   * If `true`, clicking outside the modal will not fire the `onClose` callback.
   * @default false
   */
  disableBackdropClick?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({
  title,
  description,
  content,
  actions,
  disableBackdropClick,
  onClose,
  ...rest
}) => (
  <Dialog
    {...rest}
    onClose={(_event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') {
        return;
      }
      return onClose && onClose();
    }}
  >
    {title && (
      <DialogTitle component="div">
        <Typography variant="h5" sx={{ mb: description ? 1 : 0 }}>
          {title}
        </Typography>
        {isValidElement(description) ? (
          description
        ) : (
          <Typography color="text.secondary">{description}</Typography>
        )}
      </DialogTitle>
    )}
    {content && (
      <DialogContent>
        {isValidElement(content) ? (
          content
        ) : (
          <Typography color="text.secondary">{content}</Typography>
        )}
      </DialogContent>
    )}
    {actions && (
      <DialogActions>
        {isValidElement(actions) ? (
          actions
        ) : actions && typeof actions === 'object' && 'primary' in actions ? (
          <>
            {actions.secondary && (
              <Button
                variant="outlined"
                color="secondary"
                {...actions.secondary}
                {...(!actions.secondary.onClick && {
                  onClick: onClose,
                })}
              >
                {actions.secondary.text}
              </Button>
            )}
            {actions.primary && (
              <Button
                autoFocus
                {...actions.primary}
                {...(!actions.primary.onClick && {
                  onClick: onClose,
                })}
              >
                {actions.primary.text}
              </Button>
            )}
          </>
        ) : undefined}
      </DialogActions>
    )}
  </Dialog>
);

export default Modal;
