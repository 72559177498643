import { FC, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const OIDCKeepSessionHandler: FC = () => {
  const auth = useAuth();

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent().catch((e) => console.error('Could not sign in silently', e));
    });
  }, [auth, auth.events, auth.signinSilent]);

  return null;
};

export default OIDCKeepSessionHandler;
