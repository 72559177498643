import { useMutation } from '@tanstack/react-query';

import { CreateJobData, JobRepository } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';

export const useCaseCreateJob = () => {
  const { createJob } = useHookInjection<JobRepository>(JobRepository);
  const { mutateAsync } = useMutation<{ id: ID }, Error, CreateJobData>({
    mutationFn: createJob,
  });
  return {
    mutateAsync,
  };
};
