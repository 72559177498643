import { Box, Collapse, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { FC, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useAtom } from 'jotai';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import { ID } from 'domain/types/ID';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { componentShadows } from 'targets/web/theme/shadows';
import { Button, DatePicker, Select, SimpleSelectItem, TextField } from 'targets/web/components';

import { AircraftAutocomplete } from './AircraftAutocomplete';
import { CustomerAutocomplete } from './CustomerAutocomplete';
import { StationAutocomplete } from './StationAutocomplete';

export interface Filters {
  phrase: string;
  customerId: ID;
  stationId: ID;
  aircraftId: ID;
  serviceTypeId: ID;
  dateStart: string;
  dateEnd: string;
}

interface FiltersContainerProps {
  onFiltersChange: (values: Partial<Filters>) => void;
}

const filtersCollapsedAtom = atomWithToggleAndStorage('isFilterContainerCollapsed', false);

export const FiltersContainer: FC<FiltersContainerProps> = ({ onFiltersChange }) => {
  const t = useTranslationPrefix('components.filters_container');

  const { serviceTypes } = useCaseGetServiceTypes();

  const services: SimpleSelectItem[] = useMemo(
    () =>
      [
        {
          value: 'all',
          label: 'All',
        },
      ].concat(serviceTypes.map(({ name, id }) => ({ label: name, value: id }))),
    [serviceTypes],
  );

  const [filtersCollapsed, toggleFilters] = useAtom(filtersCollapsedAtom);

  const onClickHandler = useCallback(() => toggleFilters(), [toggleFilters]);

  const debouncedSearchHandler = useCallback(
    debounce((e) => {
      const phrase = e.target.value.trim();
      onFiltersChange({ phrase: phrase.length > 2 ? phrase : undefined });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Stack
      width={1}
      sx={{
        padding: 6,
        borderRadius: 1,
        backgroundColor: 'background.default',
        boxShadow: componentShadows.card,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('header')}</Typography>
        <Button
          size="small"
          variant="text"
          endIcon={filtersCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={onClickHandler}
        >
          {filtersCollapsed ? t('hide_filters') : t('show_filters')}
        </Button>
      </Stack>

      <Collapse in={filtersCollapsed}>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                onChange={debouncedSearchHandler}
                placeholder={t('search_label')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                defaultValue={startOfMonth(Date.now())}
                label={t('date_from_label')}
                format="MMM dd yyyy"
                onChange={(value) =>
                  onFiltersChange({
                    dateStart: value ? startOfDay(value).toISOString() : undefined,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                defaultValue={endOfMonth(Date.now())}
                label={t('date_to_label')}
                format="MMM dd yyyy"
                onChange={(value) => {
                  onFiltersChange({ dateEnd: value ? endOfDay(value).toISOString() : undefined });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <StationAutocomplete
                onChange={(stationId) => {
                  onFiltersChange({ stationId });
                }}
              />
            </Grid>

            <Grid item xs>
              <CustomerAutocomplete
                onChange={(customerId) => {
                  onFiltersChange({ customerId });
                }}
              />
            </Grid>
            <Grid item xs>
              <AircraftAutocomplete
                onChange={(aircraftId) => {
                  onFiltersChange({ aircraftId });
                }}
              />
            </Grid>
            <Grid item xs>
              <Select
                label={t('service_label')}
                items={services}
                defaultValue={'all'}
                onChange={({ target }) =>
                  onFiltersChange({
                    serviceTypeId: target.value !== 'all' ? ID(target.value) : undefined,
                  })
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Stack>
  );
};
