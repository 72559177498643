import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { DashboardNavigation } from 'targets/web/modules/dashboard/components/Navigation/DashboardNavigation';
import { DashboardToolbar } from 'targets/web/modules/dashboard/components/DashboardToolbar/DashboardToolbar';

const DashboardView: FC = () => (
  <Box height="100vh" width="100%" display="flex">
    <DashboardNavigation />

    <Stack flex="1" overflow="hidden" sx={{ paddingTop: 16 }}>
      <DashboardToolbar />

      <Stack
        gap={4}
        sx={{
          flex: 1,
          alignItems: 'center',
          width: 1,
          overflow: 'auto',
          padding: 4,
          backgroundColor: 'background.neutral',
          // Limit the content width on wider screens
          '& > *': {
            maxWidth: {
              xs: 1,
              xl: 0.85,
              xxl: 0.7,
              xxxl: 0.55,
              x4k: 0.4,
            },
          },
        }}
      >
        <Outlet />
      </Stack>
    </Stack>
  </Box>
);

export default DashboardView;
