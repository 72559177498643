import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container, ServiceDetailsField } from './common';

export type TimeTrackingServiceDetailsPreviewData = {
  adjustedLoggedTimeBillable: string;
  realLoggedTimeBillable: string;
  adjustedLoggedTimeNonBillable: string;
  realLoggedTimeNonBillable: string;
};

export interface TimeTrackingServiceDetailsPreviewProps {
  data: TimeTrackingServiceDetailsPreviewData;
}

export const TimeTrackingServiceDetailsPreview: FC<TimeTrackingServiceDetailsPreviewProps> = ({
  data,
}) => {
  const t = useTranslationPrefix('jobs.service_details.time_tracking');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h5">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField
              title={t('adjusted_logged_time_billable_label')}
              value={data.adjustedLoggedTimeBillable}
            />
            <ServiceDetailsField
              title={t('real_logged_time_billable_label')}
              value={data.realLoggedTimeBillable}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField
              title={t('adjusted_logged_time_nonbillable_label')}
              value={data.adjustedLoggedTimeNonBillable}
            />
            <ServiceDetailsField
              title={t('real_logged_time_nonbillable_label')}
              value={data.realLoggedTimeNonBillable}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
