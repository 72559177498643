import { FC } from 'react';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import z from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { DatePicker } from 'targets/web/components';

import { Container } from './common';

export const AvailabilityJobDetailsSchema = z.object({
  eta: z.string().optional(),
  etd: z.string().optional(),
  scheduled: z.string().optional(),
  deliverBy: z.string(),
});

export type AvailabilityJobDetailsData = z.infer<typeof AvailabilityJobDetailsSchema>;

export interface AvailabilityJobDetailsProps {
  disabled: boolean;
}

export const AvailabilityJobDetails: FC<AvailabilityJobDetailsProps> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<AvailabilityJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.availability_details');
  return (
    <Container spacing={4}>
      <Typography variant="h5">{t('title')}</Typography>

      <DatePicker
        label={t('eta_label')}
        value={values.eta ? new Date(values.eta) : null}
        disabled={disabled}
        onChange={(e) => setFieldValue('eta', e ? e.toISOString() : undefined)}
      />
      <DatePicker
        label={t('etd_label')}
        value={values.etd ? new Date(values.etd) : null}
        disabled={disabled}
        onChange={(e) => setFieldValue('etd', e ? e.toISOString() : undefined)}
      />
      <DatePicker
        label={t('scheduled_label')}
        value={values.scheduled ? new Date(values.scheduled) : null}
        disabled={disabled}
        onChange={(e) => setFieldValue('scheduled', e ? e.toISOString() : undefined)}
      />
      <DatePicker
        label={t('deliver_by_label')}
        value={values.deliverBy ? new Date(values.deliverBy) : null}
        disabled={disabled}
        onChange={(e) => setFieldValue('deliverBy', e ? e.toISOString() : undefined)}
      />
    </Container>
  );
};
