import { atom, useAtom } from 'jotai';
import { endOfMonth, startOfMonth } from 'date-fns';
import { atomWithStorage } from 'jotai/utils';

import { SearchJobsParams } from 'domain/repositories/IJobRepository';

export type ViewType = 'grid-view' | 'table-view';
export type LogType = 'job-log' | 'service-log';

const viewTypeAtom = atomWithStorage<ViewType>('ViewType', 'grid-view');
const logTypeAtom = atom<LogType>('job-log');
const filtersAtom = atom<SearchJobsParams>({
  dateStart: startOfMonth(Date.now()).toISOString(),
  dateEnd: endOfMonth(Date.now()).toISOString(),
});

export const useJobViewFilters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [viewType, setViewType] = useAtom(viewTypeAtom);
  const [logType, setLogType] = useAtom(logTypeAtom);

  return {
    filters,
    setFilters,
    viewType,
    setViewType,
    logType,
    setLogType,
  };
};
