import { ContainerModule } from 'inversify';

import 'reflect-metadata';
import { JobRepository } from 'domain/repositories/IJobRepository';
import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { AircraftRepository } from 'domain/repositories/IAircraftRepository';
import { CustomerRepository } from 'domain/repositories/ICustomerRepository';
import { AttachmentsRepository } from 'domain/repositories/IAttachmentsRepository';
import { StationRepository } from 'domain/repositories/IStationRepository';

import { useTsRestJobRepository } from './providers/jobs/useTsRestJobRepository';
import { useTsRestServiceRepository } from './providers/services/useTsRestServiceRepository';
import { useTsRestAircraftRepository } from './providers/aircraft/useTsRestAircraftRepository';
import { useTsRestCustomerRepository } from './providers/customers/useTsRestCustomerRepository';
import { useTsRestAttachmentsRepository } from './providers/attachments/useTsRestAttachmentsRepository';
import { useTsRestStationRepository } from './providers/stations/useTsRestStationRepository';

const infrastructureModule = new ContainerModule((bind) => {
  bind(JobRepository).toFunction(useTsRestJobRepository);
  bind(ServiceRepository).toFunction(useTsRestServiceRepository);
  bind(AttachmentsRepository).toFunction(useTsRestAttachmentsRepository);
  bind(AircraftRepository).toFunction(useTsRestAircraftRepository);
  bind(CustomerRepository).toFunction(useTsRestCustomerRepository);
  bind(StationRepository).toFunction(useTsRestStationRepository);
});

export default infrastructureModule;
