import {
  FormControlLabel,
  Stack,
  useTheme,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
} from '@mui/material';

export type CheckboxCardProps = MuiCheckboxProps & {
  label: string;
  description?: string;
};

const CheckboxCard = ({ label, description, ...rest }: CheckboxCardProps) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={2}
      sx={{
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: 1,
        backgroundColor: 'background.neutral',
      }}
    >
      <FormControlLabel
        control={<MuiCheckbox {...rest} />}
        label={label}
        slotProps={{
          typography: {
            sx: { ...theme.typography.labelLarge },
          },
        }}
      />
      {description && (
        <Typography color="secondary" sx={{ paddingLeft: 10.5 }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default CheckboxCard;
