import { InjectableHook } from 'domain/types/InjectableHook';
import { ID } from 'domain/types/ID';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { JobStatusCount } from 'domain/entities/JobStatusCount';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { CommonSearchParams } from 'domain/types/CommonSearchParams';
import { Service } from 'domain/entities/Service';
import { ServiceType } from 'domain/entities/ServiceType';
import { Entity } from 'domain/types/Entity';

export type SearchServicesParams = Partial<
  CommonSearchParams & {
    jobId: ID;
    serviceTypeId: ID;
    status: ServiceStatus;
    aircraftId: ID;
    customerId: ID;
    phrase: string;
    stationId: ID;
  }
>;

export const ServiceRepository = Symbol('ServiceRepository');

export type ServiceRepository = InjectableHook<{
  searchServices(params: SearchServicesParams): Promise<PaginatedResult<Service>>;
  getCountByStatus(params: SearchServicesParams): Promise<JobStatusCount>;
  startTimer(serviceId: ID, billable: boolean): Promise<void>;
  getAvailableTypes(): Promise<{ serviceTypes: Entity<ServiceType>[] }>;
}>;
