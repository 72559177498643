import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, PreviewBox, ToggleButton } from 'targets/web/components';
import CheckboxCard from 'targets/web/components/CheckboxCard';
import { NumberCounter } from 'targets/web/components/NumberCounter';
import { QuestionBox } from 'targets/web/modules/jobs/components/QuestionBox';
import { PreWorkQuestionnaireDetailsData } from 'targets/web/modules/jobs/components/PreWorkQuestionnaire/PreWorkQuestionnaireDialog';
import Link from 'targets/web/components/Link';

const details = {
  jobId: 'N7777QX-0001',
  serviceId: 'N7777QX-0001',
  customerId: 'EMPIREAVI',
  aircraftType: 'AG-22-0002351',
};

export const PreWorkQuestionnaireForm: FC = () => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire');
  const { values, setFieldValue } = useFormikContext<PreWorkQuestionnaireDetailsData>();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" gap={4} marginBottom={4}>
        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.job')}
          subheader={details.jobId}
        ></PreviewBox>
        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.service')}
          subheader={details.serviceId}
        ></PreviewBox>
        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.customer')}
          subheader={details.customerId}
        ></PreviewBox>
        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.aircraft_type')}
          subheader={details.aircraftType}
        ></PreviewBox>
      </Stack>
      <Stack direction="row" gap={4}>
        <Stack gap={6}>
          <Typography variant="h6">{'Tasks'}</Typography>
          <CheckboxCard
            name="availabilityVerified"
            label={t('tasks.aircraft_verification_title')}
            description={t('tasks.aircraft_verification_description')}
            checked={values.availabilityVerified}
            onChange={(e) => setFieldValue('availabilityVerified', e.target.checked)}
          />
          <CheckboxCard
            label={t('tasks.conduct_walkaround_title')}
            description={t('tasks.conduct_walkaround_description')}
            checked={values.walkaroundConducted}
            onChange={(e) => setFieldValue('walkaroundConducted', e.target.checked)}
          />
          <CheckboxCard
            label={t('tasks.check_hangar_title')}
            checked={values.hangarChecked}
            onChange={(e) => setFieldValue('hangarChecked', e.target.checked)}
          />
        </Stack>
        <Stack gap={4}>
          <Typography variant="h6">{t('tools.label')}</Typography>
          <Stack
            gap={4}
            sx={{
              borderRadius: 2,
              borderWith: 1,
              borderStyle: 'solid',
              borderColor: 'divider',
              padding: 4,
            }}
          >
            <NumberCounter
              label={t('tools.bottles')}
              min={0}
              max={20}
              value={values.toolsUsed.bottles}
              onChange={(value) => setFieldValue('toolsUsed.bottles', value)}
            />
            <NumberCounter
              label={t('tools.microfiber')}
              min={0}
              max={20}
              value={values.toolsUsed.microfiber}
              onChange={(value) => setFieldValue('toolsUsed.microfiber', value)}
            />
            <NumberCounter
              label={t('tools.terrycloth')}
              min={0}
              max={20}
              value={values.toolsUsed.terrycloth}
              onChange={(value) => setFieldValue('toolsUsed.terrycloth', value)}
            />
            <NumberCounter
              label={t('tools.clean_kit')}
              min={0}
              max={20}
              value={values.toolsUsed.cleanKit}
              onChange={(value) => setFieldValue('toolsUsed.cleanKit', value)}
            />
            <NumberCounter
              label={t('tools.other_supplies')}
              min={0}
              max={20}
              value={values.toolsUsed.other}
              onChange={(value) => setFieldValue('toolsUsed.other', value)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Typography variant="h6">{t('questions.label')}</Typography>
        <QuestionBox
          size="regular"
          sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
        >
          <Typography>{t('questions.prework_communication')}</Typography>
          <Stack direction="row" gap={4}>
            <Button
              startIcon={<RemoveRedEyeOutlinedIcon />}
              variant="text"
              onClick={() => {
                /* TODO (davlis): Add implementation */
              }}
            >
              <Link
                href="/assets/pre_work_communication.docx"
                target="_blank"
                download
                sx={{ textDecoration: 'none' }}
                variant="inherit"
              >
                {'View'}
              </Link>
            </Button>
            <ToggleButton
              color="primary"
              size="large"
              value={values.includeCommunication ? 'yes' : 'no'}
              onClick={(e) => setFieldValue('includeCommunication', e.value === 'yes')}
              options={[
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
            />
          </Stack>
        </QuestionBox>
        <QuestionBox
          size="regular"
          sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
        >
          <Typography>{t('questions.employees_count')}</Typography>
          <NumberCounter
            sx={{ maxWidth: '152px' }}
            min={0}
            max={20}
            value={values.employeeCount}
            onChange={(value) => setFieldValue('employeeCount', value)}
          />
        </QuestionBox>
      </Stack>
    </Stack>
  );
};
