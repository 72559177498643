import { FC } from 'react';

import {
  BasicServiceDetailsPreview,
  BasicServiceDetailsPreviewData,
} from './BasicServiceDetailsPreview';
import {
  TimeTrackingServiceDetailsPreview,
  TimeTrackingServiceDetailsPreviewData,
} from './TimeTrackingServiceDetailsPreview';
import {
  BillingServiceDetailsPreview,
  BillingServiceDetailsPreviewData,
} from './BillingServiceDetailsPreview';
import {
  ExternalServiceDetailsPreview,
  ExternalServiceDetailsPreviewData,
} from './ExternalServiceDetailsPreview';
import {
  WaitTimeServiceDetailsPreview,
  WaitTimeServiceDetailsPreviewData,
} from './WaitTimeServiceDetailsPreview';
import { FooterServiceDetailsPreview } from './FooterServiceDetailsPreview';
import {
  NotesServiceDetailsPreview,
  NotesServiceDetailsPreviewData,
} from './NotesServiceDetailsPreview';

export type ServiceDetailsPreviewData = {
  basic: BasicServiceDetailsPreviewData;
  timeTracking: TimeTrackingServiceDetailsPreviewData;
  billing: BillingServiceDetailsPreviewData;
  external: ExternalServiceDetailsPreviewData;
  notes: NotesServiceDetailsPreviewData;
  waitTime: WaitTimeServiceDetailsPreviewData;
};

export interface ServiceDetailsPreviewProps {
  data: ServiceDetailsPreviewData;
  disabled: boolean;
  onAddFoundItem?: () => void;
  onDeleteService?: () => void;
  onStart?: () => void;
}

export const ServiceDetailsPreview: FC<ServiceDetailsPreviewProps> = ({
  data,
  disabled,
  onAddFoundItem,
  onDeleteService,
  onStart,
}) => {
  return (
    <>
      <BasicServiceDetailsPreview data={data.basic} />
      <TimeTrackingServiceDetailsPreview data={data.timeTracking} />
      <BillingServiceDetailsPreview data={data.billing} />
      <ExternalServiceDetailsPreview data={data.external} />
      <NotesServiceDetailsPreview data={data.notes} />
      <WaitTimeServiceDetailsPreview data={data.waitTime} />
      <FooterServiceDetailsPreview
        disabled={disabled}
        onStart={onStart}
        onAddFoundItem={onAddFoundItem}
        onDeleteService={onDeleteService}
      />
    </>
  );
};
