import { Box } from '@mui/material';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button } from 'targets/web/components';

import { Container } from './common';

export interface FooterServiceDetailsPreviewProps {
  disabled: boolean;
  onAddFoundItem?: () => void;
  onDeleteService?: () => void;
  onStart?: () => void;
}

export const FooterServiceDetailsPreview: FC<FooterServiceDetailsPreviewProps> = ({
  disabled,
  onAddFoundItem,
  onDeleteService,
  onStart,
}) => {
  const t = useTranslationPrefix('jobs.service_details.footer');
  return (
    <Container
      justifyContent="flex-end"
      direction="row"
      gap={2}
      left={0}
      padding={4}
      position="sticky"
      width="100%"
      bottom={0}
    >
      {onAddFoundItem && (
        <Button
          disabled={disabled}
          onClick={onAddFoundItem}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          {t('add_found_item')}
        </Button>
      )}
      {onDeleteService && (
        <Button
          disabled={disabled}
          onClick={onDeleteService}
          variant="text"
          color="error"
          startIcon={<DeleteOutlineIcon />}
        >
          {t('delete_service')}
        </Button>
      )}
      <Box flex={1} />
      {onStart && (
        <Button
          disabled={disabled}
          onClick={onStart}
          variant="contained"
          color="success"
          startIcon={<PlayArrowIcon />}
        >
          {t('start')}
        </Button>
      )}
    </Container>
  );
};
