import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  CircularProgress,
} from '@mui/material';

import TextField from 'targets/web/components/TextField';

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  label?: string;
  renderInput?: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'];
}

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  loading,
  label,
  renderInput,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <MuiAutocomplete
    {...rest}
    loading={loading}
    slotProps={{
      paper: {
        sx: {
          marginTop: 2,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'outline',
          '.MuiAutocomplete-listbox > .MuiAutocomplete-option': {
            padding: 3,
          },
        },
      },
    }}
    renderInput={(params) => {
      if (renderInput) return renderInput(params);

      return (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="primary" size={24} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    }}
  />
);
