import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ID } from 'domain/types/ID';
import { useCaseSubmitPreWorkQuestionnaire } from 'application/jobs/useCases/useCaseSubmitPreWorkQuestionnaire';
import {
  PreWorkQuestionnaireDetailsData,
  PreWorkQuestionnaireDialog,
} from 'targets/web/modules/jobs/components/PreWorkQuestionnaire/PreWorkQuestionnaireDialog';

export const PreWorkQuestionnaireView: FC = () => {
  const { jobId } = useParams();
  const { mutateAsync: submitPreWorkQuestionnaire } = useCaseSubmitPreWorkQuestionnaire();
  const [open, setOpen] = useState(true);

  const handleSubmit = async (values: PreWorkQuestionnaireDetailsData) => {
    await submitPreWorkQuestionnaire({
      jobId: ID(jobId as string),
      includeCommunication: values.includeCommunication,
      toolsUsed: values.toolsUsed,
      damagesFound: values.damageFound,
      employeeCount: values.employeeCount,
    });
    setOpen(false);
  };

  return (
    <PreWorkQuestionnaireDialog
      onSubmit={handleSubmit}
      open={open}
      onClose={() => setOpen(false)}
    />
  );
};
