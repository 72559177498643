export const jobsRoutes = {
  jobs: `/jobs`,
  internalRoutes: {
    new: 'new',
    details: ':jobId/details',
    // FIXME: Should include jobId & serviceID
    serviceDetails: 'service-details',
    preWorkQuestionnaire: ':jobId/pre-work-questionnaire',
  },
} as const;
