import { FC } from 'react';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Toolbar } from 'targets/web/components/Toolbar';
import IconButton from 'targets/web/components/IconButton';
import { PrimeFlightIcon } from 'targets/web/components/Icons';
import { useNavigationDrawer } from 'targets/web/modules/dashboard/hooks/useNavigationDrawer';

export const DashboardToolbar: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const theme = useTheme();

  const { toggle } = useNavigationDrawer();

  return (
    <Toolbar sx={{ zIndex: isDesktop ? theme.zIndex.drawer + 1 : undefined }}>
      {!isDesktop && (
        <IconButton variant="text" size="medium" onClick={() => toggle()}>
          <MenuIcon />
        </IconButton>
      )}
      <PrimeFlightIcon />
    </Toolbar>
  );
};
