import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import z from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Select, TextField } from 'targets/web/components';

import { Container } from './common';

export const AircraftJobDetailsSchema = z.object({
  aircraft: z.string(),
});

export type AircraftJobDetailsData = z.infer<typeof AircraftJobDetailsSchema>;

export interface AircraftJobDetailsProps {
  disabled: boolean;
  aircrafts: {
    id: string;
    name: string;
    regNumber: string;
    serialNumber: string;
    type: string;
  }[];
}

export const AircraftJobDetails: FC<AircraftJobDetailsProps> = ({ disabled, aircrafts }) => {
  const { values, setFieldValue } = useFormikContext<AircraftJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.aircraft_details');
  const selectedAricraft = aircrafts.find(({ id }) => id === values.aircraft);
  return (
    <Container gap={4}>
      <Typography variant="h5">{t('title')}</Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Select
            label={t('aircraft_label')}
            name="aircraft"
            value={values.aircraft}
            fullWidth
            disabled={disabled}
            items={aircrafts.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={(e) => setFieldValue('aircraft', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('reg_label')}
            name="reg"
            readOnly={true}
            value={selectedAricraft?.regNumber}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('serial_label')}
            name="serial"
            value={selectedAricraft?.serialNumber}
            fullWidth
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('type_label')}
            name="type"
            value={selectedAricraft?.type}
            fullWidth
            readOnly={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
