import { useQuery } from '@tanstack/react-query';

import { SearchStationParams, StationRepository } from 'domain/repositories/IStationRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchStation = (params: SearchStationParams) => {
  const { searchStation } = useHookInjection<StationRepository>(StationRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['STATION', params] as const,
    queryFn: ({ queryKey }) => searchStation(queryKey[1]),
    initialData: { items: [], total: 0 },
  });

  return {
    ...data,
    isLoading: isLoading || isFetching,
  };
};
