import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container, ServiceDetailsField } from './common';

export type BasicServiceDetailsPreviewData = {
  service: string;
  scheduled: string;
  dueDate: string;
  woNumber: string;
  recurringType: string;
  serviceLocation: string;
  estimatedTime: string;
  employeeCount: number;
  poNumber: string;
  contractType: string;
};

export interface BasicServiceDetailsPreviewProps {
  data: BasicServiceDetailsPreviewData;
}

export const BasicServiceDetailsPreview: FC<BasicServiceDetailsPreviewProps> = ({ data }) => {
  const t = useTranslationPrefix('jobs.service_details.basic');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h5">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('service_label')} value={data.service} />
            <ServiceDetailsField title={t('scheduled_label')} value={data.scheduled} />
            <ServiceDetailsField title={t('due_date_label')} value={data.dueDate} />
            <ServiceDetailsField title={t('wo_number_label')} value={data.woNumber} />
            <ServiceDetailsField title={t('recurring_type_label')} value={data.recurringType} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('service_location_label')} value={data.serviceLocation} />
            <ServiceDetailsField title={t('estimated_time_label')} value={data.estimatedTime} />
            <ServiceDetailsField
              title={t('employee_count_label')}
              value={`${data.employeeCount} Employees`}
            />
            <ServiceDetailsField title={t('po_number_label')} value={data.poNumber} />
            <ServiceDetailsField title={t('contract_type_label')} value={data.contractType} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
