import { IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import { ServiceDetailsPreview } from 'targets/web/modules/jobs/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import ReactRouterLink from 'targets/web/components/ReactRouterLink';

export const ServiceDetailsView: FC = () => {
  const navigate = useNavigate();

  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.service_details');
  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate('../')} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
          <Breadcrumbs
            linkComponent={ReactRouterLink}
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('specific_job_details', { job: 'N7777QX-0001' }),
                url: '/example-job-id/details',
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
      </Stack>
      <ServiceDetailsPreview
        data={{
          basic: {
            service: 'Service',
            scheduled: 'Scheduled',
            dueDate: 'Due Date',
            woNumber: 'WO Number',
            recurringType: 'Recurring Type',
            serviceLocation: 'Service Location',
            estimatedTime: 'Estimated Time',
            employeeCount: 0,
            poNumber: 'PO Number',
            contractType: 'Contract Type',
          },
          timeTracking: {
            adjustedLoggedTimeBillable: 'Adjusted Logged Time Billable',
            realLoggedTimeBillable: 'Real Logged Time Billable',
            adjustedLoggedTimeNonBillable: 'Adjusted Logged Time Non Billable',
            realLoggedTimeNonBillable: 'Real Logged Time Non Billable',
          },
          billing: {
            priceList: 'Price List',
            type: 'Type',
            amount: 'Amount',
            notes: null,
          },
          external: {
            station: 'Station',
            customer: 'Customer',
            service: 'Service',
            aircraftType: 'Aircraft Type',
          },
          notes: {
            notes: 'Notes',
          },
          waitTime: {
            time: 'Time',
            reason: 'Reason',
            notes: 'Notes',
          },
        }}
        disabled={false}
        // eslint-disable-next-line no-console
        onStart={() => console.log('START')}
        // eslint-disable-next-line no-console
        onAddFoundItem={() => console.log('ADD FOUND ITEM')}
        // eslint-disable-next-line no-console
        onDeleteService={() => console.log('DELETE')}
      />
    </>
  );
};
