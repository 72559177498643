import { useQuery } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseGetServiceTypes = () => {
  const { getAvailableTypes } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['SERVICE_TYPES'] as const,
    queryFn: getAvailableTypes,
    initialData: { serviceTypes: [] },
  });

  return {
    ...data,
    isLoading,
    isFetching,
  };
};
