import { useAuth } from 'react-oidc-context';
import { useCallback, useMemo } from 'react';
import contract from 'api/infrastructure/contract/stations.route';
import { initClient } from '@ts-rest/core';

import { StationRepository } from 'domain/repositories/IStationRepository';

type RepositoryFunctions = ReturnType<StationRepository>;

export const useTsRestStationRepository: StationRepository = () => {
  const auth = useAuth();

  const client = useMemo(() => {
    return initClient(contract, {
      baseUrl: import.meta.env.VITE_BACKEND_API_BASE_URL,
      baseHeaders: {
        Authorization: `Bearer ${auth.user?.access_token}`,
      },
    });
  }, [auth.user?.access_token]);

  const searchStation: RepositoryFunctions['searchStation'] = useCallback(
    async (params) => {
      const res = await client.searchStations({
        query: {
          ...params,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  return {
    searchStation,
  };
};
