import { useAuth } from 'react-oidc-context';
import { useCallback, useMemo } from 'react';
import contract from 'api/infrastructure/contract/services.route';
import { initClient } from '@ts-rest/core';

import { SearchServicesParams, ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';

const transformParams = ({ phrase, ...params }: SearchServicesParams) => {
  return {
    ...params,
    ...(phrase ? { phrase } : {}), // FIXME: Handle empty phrase on BE
  };
};

type RepositoryFunctions = ReturnType<ServiceRepository>;

export const useTsRestServiceRepository: ServiceRepository = () => {
  const auth = useAuth();

  const client = useMemo(() => {
    return initClient(contract, {
      baseUrl: import.meta.env.VITE_BACKEND_API_BASE_URL,
      baseHeaders: {
        Authorization: `Bearer ${auth.user?.access_token}`,
      },
    });
  }, [auth.user?.access_token]);

  const searchServices: RepositoryFunctions['searchServices'] = useCallback(
    async (params: SearchServicesParams) => {
      const res = await client.searchServices({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const getCountByStatus: RepositoryFunctions['getCountByStatus'] = useCallback(
    async (params) => {
      const res = await client.getCountServicesStatus({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const startTimer: RepositoryFunctions['startTimer'] = useCallback(
    async (serviceId: ID, billable: boolean) => {
      const res = await client.startTimer({ params: { serviceId }, body: { billable } });

      if (res.status === 204) {
        return;
      }
      throw res;
    },
    [client],
  );

  const getAvailableTypes: RepositoryFunctions['getAvailableTypes'] = useCallback(async () => {
    const res = await client.getAllServiceTypes();

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  }, [client]);

  return {
    searchServices,
    getCountByStatus,
    getAvailableTypes,
    startTimer,
  };
};
