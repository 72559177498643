import { Damage } from 'api/domain/entities/documents/PreWorkQuestionnaire';

import { Entity } from 'domain/types/Entity';
import { InjectableHook } from 'domain/types/InjectableHook';
import { ID } from 'domain/types/ID';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { JobStatus } from 'domain/entities/JobStatus';
import { Job } from 'domain/entities/Job';
import { JobStatusCount } from 'domain/entities/JobStatusCount';
import { CommonSearchParams } from 'domain/types/CommonSearchParams';
import { GetJobResponse } from 'domain/entities/GetJobResponse';

export type SearchJobsParams = Partial<
  CommonSearchParams & {
    phrase: string;
    status: JobStatus;
    customerId: ID;
    stationId: ID;
    aircraftId: ID;
    serviceTypeId: ID;
  }
>;

export type CreateJobData = {
  name: string;
  notes?: string;
  aircraftId: ID;
  customerId: ID;
  due: Date;
  scheduledStart: Date;
  eta: Date;
  etd: Date;
  aircraftSerialNo: string;
  stationId: ID;
  serviceTypes: Set<ID>;
};

export type SubmitPreWorkQuestionnaireData = {
  jobId: ID;
  includeCommunication: boolean;
  toolsUsed: {
    bottles: number;
    microfiber: number;
    terrycloth: number;
    cleanKit: number;
    other: number;
  };
  damagesFound: Set<Damage>;
  employeeCount: number;
};

export type FetchJobParams = {
  id?: string;
};

export const JobRepository = Symbol('JobRepository');

export type JobRepository = InjectableHook<{
  searchJobs(params: SearchJobsParams): Promise<PaginatedResult<Entity<Job>>>;
  fetchJob(params: FetchJobParams): Promise<GetJobResponse>;
  getCountByStatus(params: SearchJobsParams): Promise<JobStatusCount>;
  createJob(params: CreateJobData): Promise<{ id: ID }>;
  submitPreWorkQuestionnaire(params: SubmitPreWorkQuestionnaireData): Promise<void>;
}>;
