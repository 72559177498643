import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-600.css';

import { TypographyOptions } from '@mui/material/styles/createTypography';

import { lineHeightPxToUnitless, pxToRem } from './helpers';
import { FONT_SIZE_BODY, FONT_SIZE_HTML } from './consts';

const FONT_FAMILY = 'Inter, sans-serif';
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_BOLD = 600;

const FONT_SIZE_H1 = 56;
const FONT_SIZE_H2 = 48;
const FONT_SIZE_H3 = 32;
const FONT_SIZE_H4 = 24;
const FONT_SIZE_H5 = 20;
const FONT_SIZE_H6 = 18;
const FONT_SIZE_BODY_BASE = 16;
const FONT_SIZE_BODY_SMALL = 14;
const FONT_SIZE_CAPTION = 12;

const LINE_HEIGHT_H1 = 72;
const LINE_HEIGHT_H2 = 64;
const LINE_HEIGHT_H3 = 48;
const LINE_HEIGHT_H4 = 36;
const LINE_HEIGHT_H5 = 30;
const LINE_HEIGHT_H6 = 28;
const LINE_HEIGHT_BODY_BASE = 26;
const LINE_HEIGHT_BODY_SMALL = 24;
const LINE_HEIGHT_CAPTION = 18;

export const typography: TypographyOptions = {
  htmlFontSize: FONT_SIZE_HTML,
  fontSize: FONT_SIZE_BODY,

  fontFamily: FONT_FAMILY,
  fontWeightLight: undefined,
  fontWeightRegular: FONT_WEIGHT_REGULAR,
  fontWeightMedium: undefined,
  fontWeightBold: FONT_WEIGHT_BOLD,

  h1: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H1),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H1, FONT_SIZE_H1),
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H2),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H2, FONT_SIZE_H2),
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H3),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H3, FONT_SIZE_H3),
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H4),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H4, FONT_SIZE_H4),
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H5),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H5, FONT_SIZE_H5),
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(FONT_SIZE_H6),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_H6, FONT_SIZE_H6),
  },

  body1: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(FONT_SIZE_BODY_BASE),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_BODY_BASE, FONT_SIZE_BODY_BASE),
  },
  body2: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(FONT_SIZE_BODY_SMALL),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_BODY_SMALL, FONT_SIZE_BODY_SMALL),
  },

  caption: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(FONT_SIZE_CAPTION),
    lineHeight: lineHeightPxToUnitless(LINE_HEIGHT_CAPTION, FONT_SIZE_CAPTION),
  },

  buttonLarge: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(15),
    lineHeight: lineHeightPxToUnitless(24, 15),
    textTransform: 'none',
  },
  buttonMedium: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(14),
    lineHeight: lineHeightPxToUnitless(24, 14),
    textTransform: 'none',
  },
  buttonSmall: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(13),
    lineHeight: lineHeightPxToUnitless(24, 13),
    textTransform: 'none',
  },

  input: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(16),
    lineHeight: lineHeightPxToUnitless(24, 16),
  },
  inputLabel: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(12),
    lineHeight: lineHeightPxToUnitless(12, 12),
  },
  inputHelper: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(12),
    lineHeight: lineHeightPxToUnitless(20, 12),
  },

  labelLarge: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(16),
    lineHeight: lineHeightPxToUnitless(24, 16),
  },
  labelMedium: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(14),
    lineHeight: lineHeightPxToUnitless(20, 14),
  },
  labelSmall: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(12),
    lineHeight: lineHeightPxToUnitless(16, 12),
  },

  chip: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(13),
    lineHeight: lineHeightPxToUnitless(16, 13),
  },

  tableHeader: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: pxToRem(14),
    lineHeight: lineHeightPxToUnitless(24, 14),
  },

  menuItem: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(16),
    lineHeight: lineHeightPxToUnitless(26, 16),
  },

  tooltip: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(12),
    lineHeight: lineHeightPxToUnitless(16, 12),
  },
};
