import { FC } from 'react';
import { Form } from 'formik';
import { styled } from '@mui/material';

import { PreWorkQuestionnaireForm } from 'targets/web/modules/jobs/components/PreWorkQuestionnaire/PreWorkQuestionnaireForm';

const FullWidthForm = styled(Form)(() => ({
  width: '100%',
}));

export const PreWorkQuestionnaireFormWrapper: FC = () => {
  return (
    <FullWidthForm>
      <PreWorkQuestionnaireForm />
    </FullWidthForm>
  );
};
