import { Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';

import { componentShadows } from 'targets/web/theme/shadows';

export const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: componentShadows.card,
}));

export const ServiceDetailsField: FC<{ title: string; value: string; disabled?: boolean }> = ({
  title,
  value,
  disabled,
}) => (
  <Stack direction="column">
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="body1" color={disabled ? 'textSecondary' : 'textPrimary'}>
      {value}
    </Typography>
  </Stack>
);
