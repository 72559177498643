import { Stack, StackOwnProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface PreviewBoxProps extends StackOwnProps {
  header?: string;
  subheader?: string;
  size?: 'small' | 'regular';
  color?: string; // FIXME: Palette colors only
  icon?: ReactNode;
  children?: ReactNode | ReactNode[];
}

export const PreviewBox: FC<PreviewBoxProps> = ({
  size = 'small',
  icon,
  children,
  header,
  subheader,
  color,
  ...props
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      {...props}
      sx={{
        padding: size === 'small' ? 2 : 4,
        borderRadius: 1,
        backgroundColor: 'background.neutral',
        ...props.sx,
      }}
    >
      {icon && (
        <Stack
          sx={{
            height: 'fit-content',
            padding: 1,
            borderRadius: '100%',
            color: `${color ?? 'secondary'}.main`,
            backgroundColor: `${color ?? 'secondary'}.action.selected`,
            '& > svg': {
              color: 'inherit',
            },
          }}
        >
          {icon}
        </Stack>
      )}

      {(header || subheader) && (
        <Stack spacing={1}>
          {header && (
            <Typography noWrap variant="inputLabel">
              {header}
            </Typography>
          )}
          {subheader && (
            <Typography noWrap variant={size === 'small' ? 'labelSmall' : 'labelMedium'}>
              {subheader}
            </Typography>
          )}
        </Stack>
      )}

      {children}
    </Stack>
  );
};
