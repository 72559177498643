import {
  Box,
  Checkbox,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableProps as MuiTableProps,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SortingDirection } from 'common/types/SortingOptions';

import Loader from 'targets/web/components/Loader';
import { componentShadows } from 'targets/web/theme/shadows';

import { LoaderCell, LoaderRow, LoaderWrapper, NoDataIconWrapper } from './style';

export type TableRow<T> = T & { id: string | number };

export interface TableColumn<T> {
  headerName?: ReactNode;
  field?: keyof TableRow<T>;
  align?: 'left' | 'right';
  sortable?: boolean;
  type?: 'actions';
  renderCell?: (row: TableRow<T>) => ReactNode;
  width?: number | string;
}

export type SortingFunction<T> = (
  orderBy: keyof TableRow<T>,
  orderDirection: SortingDirection,
) => void;

export interface TableProps<T> extends MuiTableProps {
  /**
   * Set of columns.
   */
  columns: TableColumn<T>[];
  /**
   * Array with data to populate the table.
   * Every array item needs to have a unique `id` property.
   */
  rows: TableRow<T>[];
  onRowClick?: (clickedRow: TableRow<T>) => void;
  /**
   * The data field to sort the rows by.
   */
  orderBy?: keyof TableRow<T>;
  /**
   * The order of the sorting sequence.
   * @default SortingDirection.Ascending
   */
  orderDirection?: SortingDirection;
  /**
   * A comparator function used to sort rows.
   */
  sortComparator?: (a: TableRow<T>, b: TableRow<T>) => number;
  /**
   * If `true`, rows will be clickable and have a `Checkbox` element for selection.
   */
  selectableRows?: boolean;
  /**
   * Callback fired when the row selection is changed.
   * @param {number} rowId The IDs of all the selected rows.
   */
  onRowSelectionChange?: (rowIds: readonly (string | number)[]) => void;
  /**
   * If `true`, the pagination component in the footer is hidden.
   * @default false
   */
  hidePagination?: boolean;
  /**
   * The zero-based index of the initial page.
   * @default 0
   */
  initialPage?: number;
  /**
   * Callback fired when the page is changed.
   * @param {number} page The new page selected.
   */
  onPageChange?: (page: number) => void;

  /**
   * Callback fired when the sorting order is changed.
   * @param {string} orderBy The new field to sort by.
   * @param {string} orderDirection The new order of the sorting sequence.
   */
  onSortChange?: SortingFunction<T>;
  /**
   * The initial number of rows to load on each page.
   * Set to `-1` to load all the rows.
   *
   * **Note:** this can be used together with `maxVisibleBodyRows`.
   *
   * @default 10
   */
  initialRowsPerPage?: number;
  /**
   * Customizes the options of the rows per page select field.
   * If less than two options are available, no select field will be displayed.
   * Use -1 for the value with a custom label to show all the rows.
   * @default [5, 10, 25]
   */
  rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
  /**
   * Callback fired when the number of rows per page is changed.
   * @param {number} rowsPerPage The new number of rows per page.
   */
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  /**
   * Sets a max-height on the `TableContainer` element to fit the specified number of body rows.
   * If this number is lower than `initialRowsPerPage`, the user will have to scroll to see all the rows.
   */
  maxVisibleBodyRows?: number;
  shouldShouldEmptyRows?: boolean;
  count?: number;
  isLoading?: boolean;

  noDataOptions?: {
    icon?: ReactNode;
    text: string;
  };
}

export const Table = <T,>({
  columns,
  rows,
  onRowClick,
  orderBy: initialOrderBy,
  orderDirection: initialOrderDirection = SortingDirection.Ascending,
  selectableRows,
  onRowSelectionChange,
  hidePagination,
  initialPage = 0,
  onPageChange,
  onSortChange,
  initialRowsPerPage = 10,
  rowsPerPageOptions = [5, 10, 25],
  onRowsPerPageChange,
  maxVisibleBodyRows,
  shouldShouldEmptyRows: shouldShouldEmptyRowsProp = false,
  size = 'medium',
  isLoading,
  count,
  noDataOptions,
  stickyHeader,
  ...props
}: TableProps<T>): ReactElement => {
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [orderDirection, setOrderDirection] = useState(initialOrderDirection);

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const [selectedIds, setSelectedIds] = useState<readonly (string | number)[]>([]);

  const computedRowHeight =
    props.padding === 'none' && !selectableRows ? 33 : size === 'small' ? 43 : 57;

  const emptyRows = rowsPerPage - rows.length;
  const columnsNumber = columns.length + (selectableRows ? 1 : 0);

  const shouldShouldEmptyRows = shouldShouldEmptyRowsProp && emptyRows > 0 && rows.length > 0;
  const shouldShouldNoDataAvailable = !isLoading && rows.length === 0;
  const shouldShowLoader = isLoading && rows.length === 0;

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  const handleSortLabelClick = useCallback(
    (_event: MouseEvent<unknown>, column: TableColumn<T>) => {
      if (column.field === undefined) {
        return;
      }

      const direction =
        column.field === orderBy && orderDirection === SortingDirection.Ascending
          ? SortingDirection.Descending
          : SortingDirection.Ascending;

      setOrderBy(column.field);
      setOrderDirection(direction);

      if (onSortChange) {
        onSortChange(column.field, direction);
      }
    },
    [orderBy, setOrderBy, orderDirection, setOrderDirection, onSortChange],
  );

  const handlePageChange = useCallback(
    (_event: unknown, newPage: number) => {
      setPage(newPage);

      if (onPageChange) {
        onPageChange(newPage);
      }
    },
    [setPage, onPageChange],
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      handlePageChange(event, 0);

      if (onRowsPerPageChange) {
        onRowsPerPageChange(parseInt(event.target.value, 10));
      }
    },
    [setRowsPerPage, onRowsPerPageChange, handlePageChange],
  );

  const handleRowClick = useCallback(
    (_event: MouseEvent<unknown>, row: TableRow<T>) => {
      if (!selectableRows) {
        onRowClick?.(row);

        return;
      }

      const selectedIndex = selectedIds.indexOf(row.id);
      let newSelected: readonly (string | number)[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedIds, row.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedIds.slice(1));
      } else if (selectedIndex === selectedIds.length - 1) {
        newSelected = newSelected.concat(selectedIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedIds.slice(0, selectedIndex),
          selectedIds.slice(selectedIndex + 1),
        );
      }

      setSelectedIds(newSelected);
      if (onRowSelectionChange) {
        onRowSelectionChange(newSelected);
      }
    },
    [selectableRows, selectedIds, onRowSelectionChange, onRowClick],
  );

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSelected =
        event.target.checked && selectedIds.length === 0 ? rows.map(({ id }) => id) : [];

      setSelectedIds(newSelected);

      if (onRowSelectionChange) {
        onRowSelectionChange([]);
      }
    },
    [selectedIds, rows, setSelectedIds, onRowSelectionChange],
  );

  const isRowSelected = useCallback(
    (id: string | number) => selectedIds.includes(id),
    [selectedIds],
  );

  return (
    <Stack
      sx={{
        boxShadow: componentShadows.card,
        backgroundColor: 'background.default',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      <TableContainer
        data-testid="table"
        sx={{
          maxHeight: maxVisibleBodyRows
            ? computedRowHeight * maxVisibleBodyRows + 1 // Add 1 for the head row
            : '100%',
        }}
      >
        <MuiTable size={size} stickyHeader={stickyHeader ?? true} {...props}>
          <TableHead>
            <TableRow>
              {selectableRows && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedIds.length > 0 && selectedIds.length < rows.length}
                    checked={rows.length > 0 && selectedIds.length === rows.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}

              {columns.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  sx={{
                    whiteSpace: 'nowrap',
                    width: column.width,
                  }}
                  align={column.align || (column.type === 'actions' ? 'right' : 'left')}
                >
                  {column.sortable && column.type !== 'actions' && column.field ? (
                    <TableSortLabel
                      active={column.field === orderBy}
                      direction={
                        column.field === orderBy ? orderDirection : SortingDirection.Ascending
                      }
                      onClick={(event) => handleSortLabelClick(event, column)}
                    >
                      {column.headerName}
                      {column.field === orderBy && (
                        <Box component="span" sx={visuallyHidden}>
                          {orderDirection === SortingDirection.Descending
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      )}
                    </TableSortLabel>
                  ) : (
                    column.headerName
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ opacity: isLoading && rows.length > 0 ? 0.6 : 1 }}>
            {shouldShowLoader && (
              <LoaderRow>
                <LoaderCell>
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                </LoaderCell>
              </LoaderRow>
            )}

            {rows.map((row) => (
              <TableRow
                key={row.id}
                hover
                onClick={(event) => handleRowClick(event, row)}
                tabIndex={-1}
                selected={isRowSelected(row.id)}
              >
                {selectableRows && (
                  <TableCell padding="checkbox">
                    <Checkbox checked={isRowSelected(row.id)} />
                  </TableCell>
                )}

                {columns.map((column, columnIndex) => (
                  <TableCell
                    key={`${row.id}${columnIndex}`}
                    className={column.type === 'actions' ? 'MuiTableCell-actions' : undefined}
                    align={column.align || (column.type === 'actions' ? 'right' : 'left')}
                  >
                    {column.renderCell
                      ? column.renderCell(row)
                      : column.field && row[column.field]
                      ? String(row[column.field])
                      : ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {shouldShouldEmptyRows && (
              <TableRow style={{ height: computedRowHeight * emptyRows }}>
                <TableCell colSpan={columnsNumber} />
              </TableRow>
            )}

            {shouldShouldNoDataAvailable && (
              <TableRow style={{ height: computedRowHeight * emptyRows }}>
                <TableCell colSpan={columnsNumber}>
                  <Stack alignItems="center" gap={6}>
                    <NoDataIconWrapper>{noDataOptions?.icon ?? <SearchIcon />}</NoDataIconWrapper>

                    <Typography variant="h4">
                      {noDataOptions?.text ?? 'No data available'}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {!hidePagination && !!rows.length && (
        <TablePagination
          sx={{ flex: 'none' }}
          data-testid="pagination"
          component="div"
          count={count || rows.length}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Stack>
  );
};

export default Table;
