import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { ID } from 'domain/types/ID';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Aircraft } from 'domain/entities/Aircraft';
import { Entity } from 'domain/types/Entity';

export type SearchAircraftParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
      typeId: ID;
    }
>;

export const AircraftRepository = Symbol('AircraftRepository');

export type AircraftRepository = InjectableHook<{
  searchAircraft(params: SearchAircraftParams): Promise<PaginatedResult<Entity<Aircraft>>>;
}>;
