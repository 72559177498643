import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';

export type SearchStationParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
    }
>;

export const StationRepository = Symbol('StationRepository');

export type StationRepository = InjectableHook<{
  searchStation(params: SearchStationParams): Promise<PaginatedResult<Entity<Station>>>;
}>;
