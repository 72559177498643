import { FC } from 'react';
import { Link as ReactRouterLinkComponent } from 'react-router-dom';

import Link, { LinkProps } from 'targets/web/components/Link';

interface ReactRouterLinkProps extends LinkProps {
  to: string;
}

const ReactRouterLink: FC<ReactRouterLinkProps> = ({ to, ...props }) => {
  return (
    <Link
      variant="body2"
      color="text.secondary"
      underline="none"
      component={ReactRouterLinkComponent}
      // @ts-expect-error TS2322
      to={to}
      {...props}
    />
  );
};

export default ReactRouterLink;
