import { Divider, IconButton, List, Toolbar, ListItem } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  NavigationItem as INavigationItem,
  NavigationItem,
  NavigationProps,
} from 'targets/web/components/Navigation';

import StyledMobileDrawer from './style';

const MobileSectionItem: FC<INavigationItem & { onClick: (item: INavigationItem) => void }> = (
  section,
) => {
  return (
    <ListItem disablePadding onClick={() => section.onClick(section)}>
      <ListItemButton>
        <ListItemIcon>{section.icon}</ListItemIcon>
        <ListItemText
          primary={section.label}
          primaryTypographyProps={{
            variant: 'body2',
            color: 'text.secondary',
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const MobileSection: FC<INavigationItem & { onClick: (item: INavigationItem) => void }> = (
  section,
) => {
  if (section.nested) {
    return (
      <>
        <MobileSectionItem {...section} />

        {section.nested.map((nested) => (
          <ListItem key={nested.url} disablePadding onClick={() => section.onClick(section)}>
            <ListItemButton>
              <ListItemIcon>{nested.icon}</ListItemIcon>
              <ListItemText
                primary={nested.label}
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.secondary',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </>
    );
  }

  return <MobileSectionItem {...section} />;
};

export const MobileDrawer: FC<NavigationProps> = ({
  open = false,
  toggleDrawer,
  links,
  onClick,
  ...rest
}) => {
  const [[top, bottom], setSections] = useState<[NavigationItem[], NavigationItem[]]>([[], []]);

  useEffect(() => {
    const top = links.filter((link) => !link.bottom);
    const bottom = links.filter((link) => link.bottom);

    setSections([top, bottom]);
  }, [links]);

  const handleOnClick = useCallback((link: INavigationItem) => onClick && onClick(link), [onClick]);

  return (
    <StyledMobileDrawer variant="temporary" open={open} anchor="left" {...rest}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </Toolbar>

      <Divider />

      <List component="nav" sx={{ height: '100%' }}>
        {top.flatMap((section) => (
          <MobileSection key={section.url ?? section.label} {...section} onClick={handleOnClick} />
        ))}

        {top.length > 0 && <Divider key="divider" sx={{ my: 1 }} />}

        {bottom.flatMap((section) => (
          <MobileSection key={section.url ?? section.label} {...section} onClick={handleOnClick} />
        ))}
      </List>
    </StyledMobileDrawer>
  );
};
