import { FC } from 'react';
import { useFormikContext } from 'formik';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button } from 'targets/web/components';

import { Container } from './common';

export interface FooterJobDetailsProps {
  disabled: boolean;
  onCancel: () => void;
}

export const FooterJobDetails: FC<FooterJobDetailsProps> = ({ disabled, onCancel }) => {
  const t = useTranslationPrefix('global');
  const { isValid } = useFormikContext();
  return (
    <Container
      justifyContent="flex-end"
      direction="row"
      gap={2}
      left={0}
      padding={4}
      position="sticky"
      width="100%"
      bottom={0}
    >
      <Button disabled={disabled} onClick={onCancel} variant="outlined" color="secondary">
        {t('cancel')}
      </Button>
      <Button disabled={disabled || !isValid} type="submit">
        {t('save')}
      </Button>
    </Container>
  );
};
