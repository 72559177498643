import { Components, PaletteOptions, SimplePaletteColorOptions, Theme } from '@mui/material/styles';

import { lineHeightUnitlessToPx, remToPx, rgbaToRgb } from './helpers';
import { TOOLBAR_HEIGHT } from './consts';
import { componentShadows } from './shadows';

export const components: Components<Theme> = {
  MuiTypography: {
    defaultProps: {
      variant: 'body1',
      color: 'text.primary',
    },
    styleOverrides: {
      body1: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
      body2: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
      caption: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary',
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      ...Object.fromEntries(
        (
          [
            'primary',
            'secondary',
            'error',
            'warning',
            'info',
            'success',
          ] as (keyof PaletteOptions)[]
        ).flatMap((color) => {
          const capitalizedColor = color.charAt(0).toUpperCase() + color.slice(1);
          return [
            [
              `contained${capitalizedColor}`,
              ({ theme }: { theme: Theme }) => ({
                '&.Mui-disabled': {
                  color: (theme.palette[color] as SimplePaletteColorOptions).contrastText,
                  backgroundColor: (theme.palette[color] as SimplePaletteColorOptions).main,
                  opacity: theme.palette.action.disabledOpacity,
                },
              }),
            ],
            [
              `outlined${capitalizedColor}`,
              ({ theme }: { theme: Theme }) => ({
                borderColor: (theme.palette[color] as SimplePaletteColorOptions).main,
                '&.Mui-disabled': {
                  color: (theme.palette[color] as SimplePaletteColorOptions).main,
                  borderColor: (theme.palette[color] as SimplePaletteColorOptions).main,
                  opacity: theme.palette.action.disabledOpacity,
                },
              }),
            ],
            [
              `text${capitalizedColor}`,
              ({ theme }: { theme: Theme }) => ({
                '&.Mui-disabled': {
                  color: (theme.palette[color] as SimplePaletteColorOptions).main,
                  opacity: theme.palette.action.disabledOpacity,
                },
              }),
            ],
          ];
        }),
      ),
      sizeLarge: ({ theme }) => {
        const paddingY = parseFloat(theme.spacing(3));
        const paddingX = parseFloat(theme.spacing(3));
        return {
          ...theme.typography.buttonLarge,
          padding: `${paddingY}px ${paddingX}px`,
          '&.MuiButton-outlined': {
            padding: `${paddingY - 1}px ${paddingX - 1}px`,
          },
        };
      },
      sizeMedium: ({ theme }) => {
        const paddingY = parseFloat(theme.spacing(2));
        const paddingX = parseFloat(theme.spacing(3));
        return {
          ...theme.typography.buttonMedium,
          padding: `${paddingY}px ${paddingX}px`,
          '&.MuiButton-outlined': {
            padding: `${paddingY - 1}px ${paddingX - 1}px`,
          },
        };
      },
      sizeSmall: ({ theme }) => {
        const paddingY = parseFloat(theme.spacing(1));
        const paddingX = parseFloat(theme.spacing(3));
        return {
          ...theme.typography.buttonSmall,
          padding: `${paddingY}px ${paddingX}px`,
          '&.MuiButton-outlined': {
            padding: `${paddingY - 1}px ${paddingX - 1}px`,
          },
        };
      },
      startIcon: {
        marginLeft: 0,
      },
      endIcon: {
        marginRight: 0,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '.MuiButton-sizeLarge': {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
        '.MuiButton-sizeMedium': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        '.MuiButton-sizeSmall': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }),
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        backgroundColor: theme.palette.background.default,
        outline: `1px solid ${theme.palette.outline}`,
        borderRadius: theme.shape.borderRadius,
        [`.MuiButton-root,
        .MuiButton-root.MuiToggleButtonGroup-grouped:not(:last-of-type),
        .MuiButton-root.MuiToggleButtonGroup-grouped:not(:first-of-type)`]: {
          borderRadius: theme.shape.borderRadius,
        },
        '.MuiButton-contained': {
          zIndex: 1,
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      flexContainer: ({ theme }) => ({
        gap: theme.spacing(4),
      }),
    },
  },
  MuiTab: {
    defaultProps: {
      iconPosition: 'start',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.labelMedium,
        textTransform: 'none',
        padding: theme.spacing(4, 2),
      }),
      labelIcon: ({ theme }) => ({
        minHeight: 0,
        '.MuiSvgIcon-root': {
          fontSize: theme.typography.h5.fontSize,
          marginRight: theme.spacing(2),
        },
      }),
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
      focusVisibleClassName: '.Mui-focusVisible',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        width: 42,
        height: 24,
        padding: 0,
        marginTop: 9,
        marginBottom: 9,
        marginLeft: 11,
        marginRight: 11,
        overflow: 'visible',
        '.MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          '.MuiSwitch-thumb': {
            width: 20,
            height: 20,
            boxSizing: 'border-box',
            boxShadow: 'none',
          },
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: theme.palette.primary.contrastText,
            '+ .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.primary.contrastText,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.action.disabledOpacity,
          },
        },
        '.MuiSwitch-track': {
          backgroundColor: theme.palette.action.active,
          borderRadius: 24 / 2,
          opacity: 1,
        },
        '&:hover .MuiSwitch-switchBase, .MuiSwitch-switchBase.Mui-focusVisible': {
          '.MuiSwitch-thumb': {
            boxShadow: componentShadows.switch,
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      }),
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
        '> .MuiSlider-rail, &.MuiSlider-trackInverted > .MuiSlider-track': {
          backgroundColor: rgbaToRgb(theme.palette.outline ?? theme.palette.background.paper),
        },
        '> .MuiSlider-track, &.MuiSlider-trackInverted > .MuiSlider-rail': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.MuiSlider-colorSecondary': {
          '> .MuiSlider-rail, &.MuiSlider-trackInverted > .MuiSlider-track': {
            backgroundColor: rgbaToRgb(theme.palette.outline ?? theme.palette.background.paper),
          },
          '> .MuiSlider-track, &.MuiSlider-trackInverted > .MuiSlider-rail': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        '&.Mui-disabled': {
          '> .MuiSlider-rail, &.MuiSlider-trackInverted > .MuiSlider-track': {
            backgroundColor: rgbaToRgb(theme.palette.action.disabled),
          },
          '> .MuiSlider-track, &.MuiSlider-trackInverted > .MuiSlider-rail': {
            backgroundColor: rgbaToRgb(theme.palette.action.disabled),
          },
        },
      }),
      rail: {
        opacity: 1,
      },
      track: {
        border: 'none',
      },
      thumb: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.dark,
        '&:before': {
          boxShadow: componentShadows.slider.primary,
        },
        '.MuiSlider-colorSecondary > &': {
          borderColor: theme.palette.secondary.dark,
          '&:before': {
            boxShadow: componentShadows.slider.secondary,
          },
        },
        '.Mui-disabled > &': {
          borderColor: theme.palette.action.disabled,
          '&:before': {
            boxShadow: 'none',
          },
        },
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      size: 'small',
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.neutral,
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.outline,
        },
        '&.Mui-error, &.Mui-focused': {
          backgroundColor: theme.palette.background.default,
        },
        '&.Mui-focused > .MuiOutlinedInput-notchedOutline > legend': {
          marginLeft: -1, // To compensate for the 2px border when the field is focused
        },
      }),
      input: ({ theme }) => ({
        ...theme.typography.input,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }),
      inputSizeSmall: ({ theme }) => ({
        ...theme.typography.input,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }),
      inputMultiline: {
        padding: 0,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      asterisk: ({ theme }) => ({
        color: theme.palette.error.main,
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.inputHelper,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.inputHelper,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2),
        width: 'min-content',
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        '.MuiTab-root.Mui-selected > &': {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1, 2),
      }),
      label: ({ theme }) => ({
        ...theme.typography.chip,
        padding: 0,
      }),
      icon: {
        color: 'inherit',
        margin: 0,
      },
      iconSmall: ({ theme }) => ({
        width: theme.spacing(4),
        height: theme.spacing(4),
      }),
      deleteIcon: {
        color: 'inherit',
        margin: 0,
      },
      deleteIconSmall: ({ theme }) => ({
        width: theme.spacing(4),
        height: theme.spacing(4),
      }),
    },
  },
  MuiAlert: {
    defaultProps: {
      variant: 'filled',
      elevation: 6,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightRegular,
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }),
      icon: ({ theme }) => ({
        fontSize: 24,
        padding: 0,
        marginRight: theme.spacing(3),
      }),
      message: {
        padding: 0,
      },
      action: ({ theme }) => ({
        padding: 0,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(-1),
      }),
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: TOOLBAR_HEIGHT,
        padding: theme.spacing(3, 4),
        boxSizing: 'border-box',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(3, 6),
        },
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        border: 'none',
      },
      paperAnchorTop: ({ theme }) => ({
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      }),
      paperAnchorBottom: ({ theme }) => ({
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      }),
      paperAnchorLeft: ({ theme }) => ({
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      }),
      paperAnchorRight: ({ theme }) => ({
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      }),
      docked: ({ theme }) => ({
        '& .MuiDrawer-paper': {
          borderRadius: 0,
        },
        '& .MuiDrawer-paperAnchorDockedTop': { borderBottom: `1px solid ${theme.palette.divider}` },
        '& .MuiDrawer-paperAnchorDockedRight': { borderLeft: `1px solid ${theme.palette.divider}` },
        '& .MuiDrawer-paperAnchorDockedBottom': { borderTop: `1px solid ${theme.palette.divider}` },
        '& .MuiDrawer-paperAnchorDockedLeft': { borderRight: `1px solid ${theme.palette.divider}` },
      }),
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: () => ({
        position: 'relative',
      }),
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTablePagination-toolbar': {
          padding: theme.spacing(3, 4),
          height: 'auto',
        },
      }),
      displayedRows: {
        margin: 0,
      },
      selectLabel: {
        margin: 0,
      },
      actions: ({ theme }) => ({
        marginLeft: theme.spacing(4),
        '& .MuiIconButton-root': {
          padding: theme.spacing(1),
        },
      }),
      input: ({ theme }) => ({
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
      }),
      menuItem: ({ theme }) => ({
        padding: theme.spacing(2),
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => {
        const lineHeight = parseFloat(
          lineHeightUnitlessToPx(
            (theme.typography.body1.lineHeight ?? 1.2) as number,
            remToPx(theme.typography.body1.fontSize ?? 0),
          ),
        );
        return {
          ...theme.typography.body1,
          '.MuiTableSortLabel-root': {
            verticalAlign: 'top',
          },
          '.MuiCheckbox-root': {
            marginTop: -10,
            marginBottom: -10,
          },
          '.MuiAvatar-root': {
            marginTop: -Math.max((40 - lineHeight) / 2, 0),
            marginBottom: -Math.max((40 - lineHeight) / 2, 0),
          },
          '.MuiChip-root': {
            verticalAlign: 'top',
            '&.MuiChip-sizeMedium': {
              marginTop: -Math.max((32 - lineHeight) / 2 + 1, 0),
              marginBottom: -Math.max((32 - lineHeight) / 2 + 1, 0),
            },
            '&.MuiChip-sizeSmall': {
              marginTop: -Math.max((24 - lineHeight) / 2, 0),
              marginBottom: -Math.max((24 - lineHeight) / 2, 0),
            },
          },
        };
      },
      head: ({ theme }) => ({
        ...theme.typography.tableHeader,
        color: theme.palette.text.secondary,
        padding: theme.spacing(4),
        '&.MuiTableCell-sizeSmall': {
          padding: theme.spacing(2),
        },
      }),
      body: ({ theme }) => ({
        padding: theme.spacing(4),
        paddingTop: theme.spacing(3.75),
        paddingBottom: theme.spacing(3.75),
        '&.MuiTableCell-sizeSmall': {
          padding: theme.spacing(2),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        '&.MuiTableCell-actions': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }),
      paddingNone: {
        '&.MuiTableCell-head': {
          padding: 0,
          paddingTop: 4, // Needed to make head rows the same height as body rows (body cells use a slightly larger font)
          paddingBottom: 4,
        },
        '&, &.MuiTableCell-body': {
          padding: 0,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        ...theme.typography.tooltip,
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.getContrastText(theme.palette.text.primary),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: () => ({
        boxShadow: componentShadows.card,
      }),
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'body1',
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        '& + .MuiDivider-middle': {
          marginLeft: theme.spacing(6),
          marginRight: theme.spacing(6),
        },
      }),
      avatar: ({ theme }) => ({
        marginRight: theme.spacing(2),
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(6),
        '.MuiCard-root > * + &': {
          paddingTop: theme.spacing(4),
        },
      }),
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(6),
        '.MuiCard-root > * + &': {
          paddingTop: theme.spacing(4),
        },
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(6),
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 6, 6),
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 6, 6),
      }),
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      size: 44,
    },
  },
  MuiLinearProgress: {
    defaultProps: {
      variant: 'determinate',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        height: 6,
        backgroundColor: theme.palette.outline,
        borderRadius: theme.shape.borderRadius,
      }),
    },
  },
};
