import { styled } from '@mui/material/styles';

import Button from 'targets/web/components/Button';

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  '&.MuiButton-sizeLarge': {
    padding: theme.spacing(3),
  },
  '&.MuiButton-sizeMedium': {
    padding: theme.spacing(2),
  },
  '&.MuiButton-sizeSmall': {
    padding: theme.spacing(1),
  },
}));
