import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import ToolsIcon from '@mui/icons-material/HandymanOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { JobStatus } from 'api/domain/entities/Job';
import { Box, Chip, ChipProps, MenuItem, Typography } from '@mui/material';
import { differenceInHours, isAfter } from 'date-fns';

import { Menu } from 'targets/web/components';

const iconsMap: Record<JobStatus, ReactElement> = {
  submitted: <HourglassEmptyIcon />,
  scheduled: <HourglassEmptyIcon />,
  in_progress: <ToolsIcon />,
  declined: <CloseIcon />,
  cancelled: <CloseIcon />,
  completed: <CheckIcon />,
};

const chipJobStatusMap = {
  submitted: {
    color: 'default' as const,
    label: 'Not Started',
  },
  cancelled: {
    color: 'error' as const,
    label: 'Canceled',
  },
  declined: {
    color: 'error' as const,
    label: 'Declined',
  },
  completed: {
    color: 'success' as const,
    label: 'Completed',
  },
};

const WARNING_THRESHOLD_IN_DAYS = 5;
const MIN_DAYS_DIFFERENCE = 1;

const differenceInDaysRoundedUp = (date: Date) =>
  Math.round(Math.abs(differenceInHours(Date.now(), date)) / 24) || MIN_DAYS_DIFFERENCE;

const getLabelAndColor = (
  status: JobStatus,
  dueDate: Date,
): Required<Pick<ChipProps, 'color' | 'label'>> => {
  const isAfterDueDate = isAfter(Date.now(), dueDate);
  const differenceInDays = differenceInDaysRoundedUp(dueDate);
  const lessDaysThanThreshold = differenceInDays <= WARNING_THRESHOLD_IN_DAYS;

  if (status === 'scheduled') {
    const label = `${differenceInDays} ${differenceInDays > 1 ? 'Days' : 'Day'}`;

    return {
      color: isAfterDueDate ? 'error' : lessDaysThanThreshold ? 'warning' : 'default',
      label: isAfterDueDate
        ? `${label} Overdue`
        : lessDaysThanThreshold
        ? `${label} Left`
        : chipJobStatusMap.submitted.label,
    };
  }

  if (status === 'in_progress') {
    return {
      color: isAfterDueDate ? 'error' : lessDaysThanThreshold ? 'warning' : 'primary',
      label: `In Progress${
        isAfterDueDate || lessDaysThanThreshold ? ` (${differenceInDays})` : ''
      }`,
    };
  }

  return chipJobStatusMap[status];
};

interface StatusChipProps {
  status: 'in_progress' | 'completed' | 'submitted' | 'scheduled' | 'declined' | 'cancelled';
  dueDate: Date;
  onSelect: (status: JobStatus) => void;
}

export const StatusChip: FC<StatusChipProps> = ({ status, dueDate, onSelect }) => {
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  const MenuItems: ({
    status: JobStatus;
    onClick: () => void;
  } & Required<Pick<ChipProps, 'color' | 'label'>>)[] = useMemo(
    () => [
      {
        status: JobStatus.Values.submitted,
        ...getLabelAndColor(JobStatus.Values.scheduled, dueDate),
        label: 'Not Started',
        onClick: () => onSelect(JobStatus.Values.submitted),
      },
      {
        status: JobStatus.Values.in_progress,
        ...getLabelAndColor(JobStatus.Values.in_progress, dueDate),
        label: 'In Progress',
        onClick: () => onSelect(JobStatus.Values.in_progress),
      },
      {
        status: JobStatus.Values.completed,
        ...chipJobStatusMap.completed,
        onClick: () => onSelect(JobStatus.Values.completed),
      },
    ],
    [dueDate, onSelect],
  );

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Chip
        ref={setMenuRef}
        {...getLabelAndColor(status, dueDate)}
        icon={iconsMap[status]}
        deleteIcon={<ArrowDropDownIcon />}
        size="small"
        clickable
        onDelete={openMenu}
        onClick={openMenu}
      />

      <Menu
        anchorEl={menuRef}
        slotProps={{
          paper: {
            sx: {
              marginTop: 2,
            },
          },
        }}
        sx={{
          '.MuiList-root.MuiMenu-list': {
            padding: 0,
          },
        }}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <Box padding={4}>
          <Typography variant="labelMedium">Select Job Status</Typography>
        </Box>

        {MenuItems.map(({ status, onClick, ...rest }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick();
              closeMenu();
            }}
            sx={{ padding: 4 }}
          >
            <Chip {...rest} icon={iconsMap[status]} size="small" />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
