import { SyntheticEvent, useCallback } from 'react';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridViewOutlined';
import ListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { JobStatus } from 'api/domain/entities/Job';

import { useCaseGetJobCountByStatus } from 'application/jobs/useCases/useCaseGetJobCountByStatus';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  LogType,
  useJobViewFilters,
  ViewType,
} from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import { getLabelWithValue, Tabs, ToggleButton } from 'targets/web/components';

export const JobViewTabs = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const t = useTranslationPrefix('jobs.jobs_view');

  const { filters, setFilters, setViewType, logType, setLogType, viewType } = useJobViewFilters();
  const { data } = useCaseGetJobCountByStatus(filters);

  const handleChange = useCallback(
    (_event: SyntheticEvent, newValue: JobStatus | 'all') => {
      setFilters((current) => ({ ...current, status: newValue !== 'all' ? newValue : undefined }));
    },
    [setFilters],
  );

  return (
    <Stack direction="row" gap={4} alignItems="center" justifyContent="space-between">
      <Tabs
        variant={isMobile ? 'scrollable' : 'standard'}
        onChange={handleChange}
        value={filters.status ?? 'all'}
        items={[
          {
            label: getLabelWithValue(t('status_filters.all'), data.all),
            value: 'all',
          },
          {
            label: getLabelWithValue(t('status_filters.not_started'), data.notStarted),
            value: JobStatus.Values.submitted,
          },
          {
            label: getLabelWithValue(t('status_filters.in_progress'), data.inProgress),
            value: JobStatus.Values.in_progress,
          },
          {
            label: getLabelWithValue(t('status_filters.completed'), data.completed),
            value: JobStatus.Values.completed,
          },
        ]}
      />

      <Stack direction="row" gap={4} alignItems="center">
        <ToggleButton
          color="primary"
          size="small"
          value={logType}
          onClick={({ value }) => setLogType(String(value) as LogType)}
          options={[
            { value: 'job-log', label: t('log_type.job_log') },
            { value: 'service-log', label: t('log_type.service_log') },
          ]}
        />

        <ToggleButton
          color="primary"
          size="small"
          value={viewType}
          onClick={({ value }) => setViewType(String(value) as ViewType)}
          options={[
            { value: 'grid-view' as const, icon: <GridViewIcon /> },
            { value: 'table-view', icon: <ListBulletedIcon /> },
          ]}
        />
      </Stack>
    </Stack>
  );
};
