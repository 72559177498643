import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import z from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, IconButton, Select } from 'targets/web/components';

import { Container } from './common';

export const ServicesJobDetailsSchema = z.object({
  services: z.array(z.string()).min(1),
});

export type ServicesJobDetailsData = z.infer<typeof ServicesJobDetailsSchema>;

export interface ServicesJobDetailsProps {
  disabled: boolean;
  services: {
    id: string;
    name: string;
  }[];
}

export const ServicesJobDetails: FC<ServicesJobDetailsProps> = ({ disabled, services }) => {
  const { values, setFieldValue } = useFormikContext<ServicesJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.services_details');
  return (
    <Container spacing={4}>
      <Typography variant="h5">{t('title')}</Typography>

      {values.services.map((service, index) => (
        <Stack key={index} direction="row" alignItems="end">
          <Stack flex={1} pr={4}>
            <Select
              sx={{ flex: 1 }}
              label={t('service_name_label')}
              name={`service_name_${index}`}
              value={service}
              fullWidth
              disabled={disabled}
              items={services.map(({ id, name }) => ({ label: name, value: id }))}
              onChange={(e) =>
                setFieldValue('services', [
                  ...values.services.filter((_, i) => i < index),
                  ...(e ? [e.target.value] : []),
                  ...values.services.filter((_, i) => i > index),
                ])
              }
            />
          </Stack>
          <IconButton
            onClick={() =>
              setFieldValue(
                'services',
                values.services.filter((_, i) => i !== index),
              )
            }
            variant="text"
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Stack>
      ))}
      <Stack alignItems="flex-start">
        <Button
          onClick={() => setFieldValue('services', [...values.services, ''])}
          variant="text"
          startIcon={<AddIcon />}
        >
          {t('add_service_button')}
        </Button>
      </Stack>
    </Container>
  );
};
