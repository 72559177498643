import { FC, useCallback, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import { Box, Chip, MenuItem, Typography } from '@mui/material';

import { Menu } from 'targets/web/components';

type BillingStatus = 'ready' | 'not-ready';

interface BillableStatusChipProps {
  status: 'in_progress' | 'completed' | 'submitted' | 'scheduled' | 'declined' | 'cancelled';
  billingStatus: BillingStatus;
  onSelect: (status: BillingStatus) => void;
}

const chipBillingStatusMap: Record<
  BillingStatus,
  { label: string; color: 'default' | 'success' | 'error' }
> = {
  'not-ready': { color: 'default', label: 'Not ready' },
  ready: { color: 'success', label: 'Ready' },
};

export const BillingStatusChip: FC<BillableStatusChipProps> = ({
  billingStatus,
  status,
  onSelect,
}) => {
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isClickable = status !== 'completed';

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Chip
        ref={setMenuRef}
        label={chipBillingStatusMap[billingStatus].label}
        color={chipBillingStatusMap[billingStatus].color}
        size="small"
        icon={<DollarIcon />}
        onClick={isClickable ? openMenu : undefined}
        onDelete={isClickable ? openMenu : undefined}
        deleteIcon={isClickable ? <ArrowDropDownIcon /> : undefined}
      />

      <Menu
        anchorEl={menuRef}
        slotProps={{
          paper: {
            sx: {
              marginTop: 2,
            },
          },
        }}
        sx={{
          '.MuiList-root.MuiMenu-list': {
            padding: 0,
          },
        }}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <Box padding={4}>
          <Typography variant="labelMedium">Select Billing Status</Typography>
        </Box>
        {[
          {
            ...chipBillingStatusMap.ready,
            onClick: () => onSelect('ready'),
          },
          {
            ...chipBillingStatusMap['not-ready'],
            onClick: () => onSelect('not-ready'),
          },
        ].map(({ label, color, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick();
              closeMenu();
            }}
            sx={{ padding: 4 }}
          >
            <Chip label={label} color={color} icon={<DollarIcon />} size="small" />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
