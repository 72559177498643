import { useQuery } from '@tanstack/react-query';

import { CustomerRepository, SearchCustomerParams } from 'domain/repositories/ICustomerRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchCustomer = (params: SearchCustomerParams) => {
  const { searchCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['CUSTOMER', params] as const,
    queryFn: ({ queryKey }) => searchCustomer(queryKey[1]),
    initialData: { items: [], total: 0 },
  });

  return {
    ...data,
    isLoading: isLoading || isFetching,
  };
};
