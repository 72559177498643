import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';

export type SearchCustomerParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
    }
>;

export const CustomerRepository = Symbol('CustomerRepository');

export type CustomerRepository = InjectableHook<{
  searchCustomer(params: SearchCustomerParams): Promise<PaginatedResult<Entity<Customer>>>;
}>;
