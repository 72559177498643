import { alpha, darken, lighten, PaletteColor, PaletteOptions } from '@mui/material';

import { baseTheme } from './baseTheme';

const WHITE = '#FFFFFF';
const BLACK = '#000000';
const NEUTRAL = '#F9F9F9';

const PRIMARY = '#096DD9';
const SECONDARY = '#5F6368';
const ERROR = '#B20707';

const WARNING = '#ED6C02';
const WARNING_LIGHT = '#FF9800';
const WARNING_DARK = '#E65100';

const INFO = '#0288D1';
const INFO_LIGHT = '#03A9F4';
const INFO_DARK = '#01579B';

const SUCCESS = '#0C8145';

const TEXT_PRIMARY = '#222222';
const TEXT_SECONDARY = '#555555';
const TEXT_PLACEHOLDER = '#A2A2A2';

const HOVER_OPACITY = 0.04;
const SELECTED_OPACITY = 0.08;
const FOCUS_OPACITY = 0.12;
const RIPPLE_OPACITY = 0.3;
const ENABLED_OPACITY = 0.5;
const DISABLED_OPACITY = 0.26;
const ALERT_CONTENT_DARKEN = 0.6;
const ALERT_BACKGROUND_LIGHTEN = 0.9;

const generateActionColors = (color: string): PaletteColor['action'] => {
  return {
    hover: alpha(color, HOVER_OPACITY),
    selected: alpha(color, SELECTED_OPACITY),
    focus: alpha(color, FOCUS_OPACITY),
    ripple: alpha(color, RIPPLE_OPACITY),
    enabled: alpha(color, ENABLED_OPACITY),
    alertContent: darken(color, ALERT_CONTENT_DARKEN),
    alertBackground: lighten(color, ALERT_BACKGROUND_LIGHTEN),
  };
};

const generatePaletteColor = (mainColor: string, name: string): PaletteColor => {
  return baseTheme.palette.augmentColor({
    color: {
      main: mainColor,
      action: generateActionColors(mainColor),
    },
    name: name,
  });
};

const colorsMap = {
  primary: PRIMARY,
  secondary: SECONDARY,
  error: ERROR,
  success: SUCCESS,
} as const;

export const palette: PaletteOptions = {
  ...Object.entries(colorsMap).reduce<Record<string, PaletteColor>>(
    (generatedColors, [colorName, colorValue]) => {
      generatedColors[colorName] = generatePaletteColor(colorValue, colorName);
      return generatedColors;
    },
    {},
  ),
  warning: {
    light: WARNING_LIGHT,
    main: WARNING,
    dark: WARNING_DARK,
    contrastText: WHITE,
    action: generateActionColors(WARNING),
  },
  info: {
    light: INFO_LIGHT,
    main: INFO,
    dark: INFO_DARK,
    contrastText: WHITE,
    action: generateActionColors(INFO),
  },
  background: {
    default: WHITE,
    paper: WHITE,
    neutral: NEUTRAL,
  },
  text: {
    primary: TEXT_PRIMARY,
    secondary: TEXT_SECONDARY,
    disabled: alpha(TEXT_SECONDARY, 0.48),
    placeholder: TEXT_PLACEHOLDER,
  },
  action: {
    hover: alpha(BLACK, HOVER_OPACITY),
    selected: alpha(BLACK, SELECTED_OPACITY),
    focus: alpha(BLACK, 0.2),
    active: alpha(BLACK, 0.54),
    disabled: alpha(BLACK, DISABLED_OPACITY),
    disabledBackground: alpha(BLACK, 0.12),
    hoverOpacity: HOVER_OPACITY,
    selectedOpacity: SELECTED_OPACITY,
    focusOpacity: FOCUS_OPACITY,
    activatedOpacity: ENABLED_OPACITY,
    disabledOpacity: DISABLED_OPACITY,
  },
  divider: alpha(BLACK, 0.12),
  outline: alpha(BLACK, 0.23),
  overlay: alpha(BLACK, 0.5),
};
