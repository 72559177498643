import { Stack, styled, Typography } from '@mui/material';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOffCsredOutlined';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { Job } from 'domain/entities/Job';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { componentShadows } from 'targets/web/theme/shadows';
import { Chip, PreviewBox, ProgressBar } from 'targets/web/components';
import { BillingStatusChip, StatusChip } from 'targets/web/modules/jobs/components/StatusChips';

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  minWidth: 280,
  maxWidth: 375,
  minHeight: 460,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: componentShadows.card,
}));

// TODO: Add translations
export const JobLogCard = ({
  id,
  name,
  completedServices = 0,
  totalServices = 0,
  status,
  customer,
  aircraft,
  notes,
  station,
  workLogTimeSummary,
  due,
}: Job) => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('components.cards.job_card');

  const hasTimeTracked = workLogTimeSummary.billable || workLogTimeSummary.nonBillable;

  return (
    <Container spacing={4} onClick={() => navigate(`${id}/details`)}>
      <Stack spacing={2} alignItems="center" direction="row">
        <ProgressBar sx={{ flex: 1 }} value={(completedServices / totalServices) * 100} />

        <Chip
          label={t('services_chip_label', { completedServices, totalServices })}
          size="small"
          color="primary"
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h5">{name}</Typography>

        <Stack direction="row" gap={2} flexWrap="wrap">
          <StatusChip
            status={status}
            dueDate={due}
            // eslint-disable-next-line no-console
            onSelect={console.log}
          />

          <BillingStatusChip
            billingStatus={workLogTimeSummary.billable > 0 ? 'ready' : 'not-ready'}
            status={status}
            // eslint-disable-next-line no-console
            onSelect={console.log}
          />
        </Stack>
      </Stack>

      <Stack spacing={2} flex={1}>
        <PreviewBox flex={1} size="regular" direction="column" width="auto" justifyContent="start">
          {[
            [t('station'), station?.code],
            [t('customer'), customer?.code],
            [t('aircraft_type'), aircraft?.code],
          ].map(([label, value], index) => (
            <Stack alignSelf="start" spacing={1} key={index}>
              <Typography variant="inputLabel">{label}</Typography>
              <Typography variant="labelSmall">{value}</Typography>
            </Stack>
          ))}
        </PreviewBox>

        {notes && (
          <PreviewBox sx={{ padding: 4 }}>
            <Stack direction="row" gap={1} flexWrap="wrap">
              <Typography variant="tooltip" color="secondary">
                {t('notes')}
              </Typography>
              <Typography
                variant="tooltip"
                color="secondary"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {notes}
              </Typography>
            </Stack>
          </PreviewBox>
        )}

        {!hasTimeTracked ? (
          <PreviewBox
            justifyContent="center"
            color="warning"
            icon={<DollarIcon />}
            subheader={t('no_time_tracked')}
          />
        ) : (
          <Stack width={1} direction="row" gap={2} flexWrap="wrap">
            <PreviewBox
              flex={1}
              color="success"
              icon={<DollarIcon />}
              header={t('billable')}
              subheader={format(new Date(workLogTimeSummary.billable), "h'h' m'm' s's'")}
            />

            <PreviewBox
              flex={1}
              color="secondary"
              icon={<MoneyOffIcon />}
              header={t('non_billable')}
              subheader={format(new Date(workLogTimeSummary.nonBillable), "h'h' m'm' s's'")}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};
