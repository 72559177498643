import { useQuery } from '@tanstack/react-query';

import { FetchJobParams, JobRepository } from 'domain/repositories/IJobRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseGetJobDetails = (params: FetchJobParams) => {
  const { fetchJob } = useHookInjection<JobRepository>(JobRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['FETCH_JOB', params] as const,
    queryFn: async ({ queryKey }) => (queryKey[1].id ? fetchJob(queryKey[1]) : null),
    enabled: !!params.id,
  });
  return {
    data,
    isLoading,
    isFetching,
  };
};
