import { FC } from 'react';
import { Stack } from '@mui/material';

import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';

export const ApprovalsView: FC = () => {
  return (
    <Stack spacing={6} flex={1}>
      <PageTitle title={'Accept/Decline Dashboard'} />
    </Stack>
  );
};
