import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Stack,
  useTheme,
} from '@mui/material';
import React, { PropsWithChildren, useId } from 'react';

import { Chip } from 'targets/web/components';

export interface SimpleSelectItem {
  label: string;
  value: string | number | undefined;
  disabled?: boolean;
}

export interface SelectProps<T> extends MuiSelectProps<T> {
  /**
   * Renders the selected values in `Chip` elements. You can only use it when the `renderValue` prop is not defined (default).
   */
  renderValuesInChips?: boolean;
  /**
   * Array of items to populate the select with. Items will be rendered in `MenuItem` elements.
   *
   * **Note:** either `items` or `children` needs to be provided.
   */
  items?: SimpleSelectItem[];
  /**
   * Adds checkmarks to the list of items in the select. You can only use it when the `items` array is defined.
   */
  itemCheckmarks?: boolean;
  /**
   * The option elements to populate the select with.
   *
   * **Note:** either `items` or `children` needs to be provided.
   */
  children?: React.ReactNode;
  /**
   * If `true`, the select will be in read-only mode,
   * @default false
   */
  readOnly?: boolean;
  /**
   * Props applied to the [`MuiMenuItem`](https://mui.com/material-ui/api/select/) element.
   */
  MuiMenuItemProps?: MenuItemProps;
  /**
   * Props applied to the [`MuiChip`](https://mui.com/material-ui/api/chip/) element.
   */
  MuiChipProps?: typeof Chip;
  helperText?: string;
  /**
   * If `true`, the label is displayed inside input.
   * @default false
   */
  embeddedLabel?: boolean;
}

export const Select = <T,>({
  renderValuesInChips,
  items,
  itemCheckmarks,
  children,
  readOnly,
  MuiMenuItemProps,
  MuiChipProps,
  value,
  renderValue,
  embeddedLabel,
  label,
  helperText,
  ...props
}: PropsWithChildren<SelectProps<T>>) => {
  const selectItems = children
    ? children
    : items
    ? itemCheckmarks
      ? items?.map((item, index) => (
          <MenuItem {...MuiMenuItemProps} key={index} value={item.value} disabled={item.disabled}>
            <Checkbox
              checked={
                item.value === value || (Array.isArray(value) && value.indexOf(item.value) > -1)
              }
            />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))
      : items?.map((item, index) => (
          <MenuItem {...MuiMenuItemProps} key={index} value={item.value} disabled={item.disabled}>
            {item.label}
          </MenuItem>
        ))
    : undefined;

  const inputValue = renderValue
    ? renderValue
    : renderValuesInChips
    ? (selected: unknown) => {
        const selectedArray: unknown[] = Array.isArray(selected) ? selected : [selected];
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {selectedArray.map((selectedValue, index) => (
              <Chip
                {...MuiChipProps}
                size={props.size}
                key={index}
                label={
                  items?.find((lookupItem) => lookupItem.value === selectedValue)?.label ||
                  (typeof selectedValue === 'string' || typeof selectedValue === 'number'
                    ? selectedValue
                    : '')
                }
              />
            ))}
          </Box>
        );
      }
    : (selected: unknown) => {
        const selectedArray: unknown[] = Array.isArray(selected) ? selected : [selected];
        return selectedArray
          .map(
            (selectedValue) =>
              items?.find((lookupItem) => lookupItem.value === selectedValue)?.label ||
              (typeof selectedValue === 'string' || typeof selectedValue === 'number'
                ? selectedValue
                : ''),
          )
          .join(', ');
      };

  const selectId = useId();
  const theme = useTheme();

  const LabelElement = (
    <InputLabel
      htmlFor={selectId}
      disabled={props.disabled ?? readOnly}
      required={props.required}
      sx={{ marginBottom: !embeddedLabel ? 2 : undefined, ...theme.typography.inputLabel }}
    >
      {label}
    </InputLabel>
  );
  return (
    <Stack spacing={2}>
      {label && !embeddedLabel && LabelElement}
      <FormControl
        size={props.size}
        fullWidth={props.fullWidth ?? true}
        required={props.required}
        disabled={props.disabled}
        error={props.error}
      >
        {label && embeddedLabel && LabelElement}

        <MuiSelect
          {...props}
          variant="outlined"
          size="small"
          value={value}
          renderValue={inputValue}
          id={selectId}
          label={embeddedLabel ? label : undefined}
          inputProps={{
            ...props.inputProps,
            ...(readOnly && { readOnly: true }),
          }}
        >
          {selectItems}
        </MuiSelect>

        {helperText && (
          <FormHelperText
            sx={{
              marginLeft: !embeddedLabel ? 0 : undefined,
              marginRight: !embeddedLabel ? 0 : undefined,
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};

export default Select;
