import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { JobsView } from 'targets/web/modules/jobs/views/Jobs.view';
import { NewJobView } from 'targets/web/modules/jobs/views/NewJob.view';
import { ServiceDetailsView } from 'targets/web/modules/jobs/views/ServiceDetails.view';
import { JobDetailsView } from 'targets/web/modules/jobs/views/JobDetails.view';
import { PreWorkQuestionnaireView } from 'targets/web/modules/jobs/views/PreWorkQuestionnaire.view';

const JobsRoutes: FC = () => (
  <Routes>
    <Route path={jobsRoutes.internalRoutes.new} element={<NewJobView />} />
    <Route path={jobsRoutes.internalRoutes.details} element={<JobDetailsView />} />
    <Route path={jobsRoutes.internalRoutes.serviceDetails} element={<ServiceDetailsView />} />
    <Route
      path={jobsRoutes.internalRoutes.preWorkQuestionnaire}
      element={<PreWorkQuestionnaireView />}
    />
    <Route index element={<JobsView />} />
  </Routes>
);

export default JobsRoutes;
