import { FC, PropsWithChildren, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';

const OIDCAxiosHandler: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      const accessToken = auth.user.access_token;
      const accessTokenInterceptor = axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      });
      return () => {
        axios.interceptors.request.eject(accessTokenInterceptor);
      };
    }
  }, [auth.user]);

  return children;
};

export default OIDCAxiosHandler;
