import { Stack, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoaderRow = styled(TableRow)(() => ({
  height: '33vh',
}));

export const LoaderCell = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  border: 'none',
}));

export const LoaderWrapper = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const NoDataIconWrapper = styled(Stack)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    fontSize: '64px',
    color: theme.palette.primary.main,
  },
}));
