import { useQuery } from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';

import { JobRepository, SearchJobsParams } from 'domain/repositories/IJobRepository';
import { JobStatusCount } from 'domain/entities/JobStatusCount';
import { useHookInjection } from 'application/hooks';

const initialData: JobStatusCount = { all: 0, completed: 0, inProgress: 0, notStarted: 0 };

export const useCaseGetJobCountByStatus = (params: Omit<SearchJobsParams, keyof Pagination>) => {
  const { getCountByStatus } = useHookInjection<JobRepository>(JobRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['JOB_STATUS_COUNT', params] as const,
    queryFn: ({ queryKey }) => getCountByStatus(queryKey[1]),
    initialData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
