import { Grid, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';

import {
  PrimaryJobDetails,
  PrimaryJobDetailsProps,
  PrimaryJobDetailsSchema,
} from './PrimaryJobDetails';
import {
  AircraftJobDetails,
  AircraftJobDetailsProps,
  AircraftJobDetailsSchema,
} from './AircraftJobDetails';
import { AvailabilityJobDetails, AvailabilityJobDetailsSchema } from './AvailabilityJobDetails';
import { NotesJobDetails, NotesJobDetailsSchema } from './NotesJobDetails';
import {
  ServicesJobDetails,
  ServicesJobDetailsProps,
  ServicesJobDetailsSchema,
} from './ServicesJobDetails';
import { FooterJobDetails } from './FooterJobDetails';

const JobDetailsDataSchema = PrimaryJobDetailsSchema.merge(
  AircraftJobDetailsSchema.merge(
    AvailabilityJobDetailsSchema.merge(NotesJobDetailsSchema.merge(ServicesJobDetailsSchema)),
  ),
);

export type JobDetailsData = z.infer<typeof JobDetailsDataSchema>;

export interface JobDetailsProps {
  initialValues: JobDetailsData;
  onSubmit?: (values: JobDetailsData) => void;
  onCancel?: () => void;
  disabled: boolean;
  stations: PrimaryJobDetailsProps['stations'];
  customers: PrimaryJobDetailsProps['customers'];
  aircrafts: AircraftJobDetailsProps['aircrafts'];
  services: ServicesJobDetailsProps['services'];
}

const FullWidthForm = styled(Form)(() => ({
  width: '100%',
}));

export const JobDetailsForm: FC<JobDetailsProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  disabled,
  stations,
  customers,
  aircrafts,
  services,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(data) =>
      onSubmit &&
      onSubmit({
        ...data,
        services: data.services.filter(Boolean),
      })
    }
    validateOnMount
    validationSchema={toFormikValidationSchema(JobDetailsDataSchema)}
  >
    <FullWidthForm>
      <Stack gap={4}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Stack spacing={4}>
              <PrimaryJobDetails disabled={disabled} stations={stations} customers={customers} />
              <AircraftJobDetails disabled={disabled} aircrafts={aircrafts} />
              <AvailabilityJobDetails disabled={disabled} />
              <NotesJobDetails disabled={disabled} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <ServicesJobDetails disabled={disabled} services={services} />
          </Grid>
        </Grid>
        {onSubmit && onCancel && <FooterJobDetails disabled={disabled} onCancel={onCancel} />}
      </Stack>
    </FullWidthForm>
  </Formik>
);
