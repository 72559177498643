import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { FC, useId } from 'react';
import { InputLabel, Stack, useTheme } from '@mui/material';

interface DatePickerProps<TDate> extends MuiDatePickerProps<TDate> {
  labelEmbedded?: string;
}

export const DatePicker: FC<DatePickerProps<Date>> = ({ label, labelEmbedded, ...rest }) => {
  const id = useId();
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={2} width="auto">
        {label && !labelEmbedded && (
          <InputLabel htmlFor={id} sx={{ ...theme.typography.inputLabel }}>
            {label}
          </InputLabel>
        )}

        <MuiDatePicker
          {...rest}
          label={labelEmbedded && label}
          closeOnSelect
          slotProps={{ field: { clearable: true } }}
        />
      </Stack>
    </LocalizationProvider>
  );
};
