import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CalendarIcon from '@mui/icons-material/EventOutlined';
import AirplaneIcon from '@mui/icons-material/FlightTakeoffOutlined';
import NoteAltIcon from '@mui/icons-material/NoteAltOutlined';
import { useMemo } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { NavigationItem, NavigationLink } from 'targets/web/components/Navigation';
import { NoteIcon } from 'targets/web/components/Icons';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';

interface AccessLimitedNavigationLink extends NavigationLink {
  requireFeatureConfig?: string;
}

type AccessLimitedNavigationItem = Omit<NavigationItem, 'nested'> & {
  nested?: AccessLimitedNavigationLink[];
};

export const useAvailableNavigationLinks = () => {
  const t = useTranslationPrefix('global.navigation');

  return useMemo(() => {
    const links: AccessLimitedNavigationItem[] = [
      {
        url: 'approvals',
        icon: <NoteIcon />,
        label: t('approve_and_decline'),
      },
      {
        url: jobsRoutes.jobs,
        icon: <AirplaneIcon />,
        label: t('jobs_and_services'),
      },
      {
        url: 'schedule',
        icon: <CalendarIcon />,
        label: t('schedule'),
      },
      {
        url: 'operations',
        icon: <NoteAltIcon />,
        label: t('operations'),
      },

      {
        url: 'users',
        icon: <UserIcon />,
        label: t('user_management'),
        bottom: true,
      },
      {
        url: 'configuration',
        icon: <SettingsIcon />,
        label: t('configuration'),
        bottom: true,
        nested: [
          {
            url: `configuration/aircrafts`,
            label: t('aircrafts'),
          },
          {
            url: `configuration/customers`,
            label: t('customers'),
          },
          {
            url: `configuration/locations`,
            label: t('locations'),
          },
          {
            url: `configuration/services`,
            label: t('services'),
          },
          {
            url: `configuration/price-lists`,
            label: t('price_lists'),
          },
        ],
      },
    ];

    return links;
  }, [t]);
};
