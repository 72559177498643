import { Navigate, Route } from 'react-router-dom';

import DashboardView from 'targets/web/modules/dashboard/views/Dashboard.view';
import PageNotFoundView from 'targets/web/modules/dashboard/views/PageNotFound.view';
import { ApprovalsView } from 'targets/web/modules/approvals/routes/Approvals.view';
import { ScheduleView } from 'targets/web/modules/schedule/routes/Schedule.view';
import { OperationsView } from 'targets/web/modules/operations/routes/Operations.view';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import JobsRoutes from 'targets/web/modules/jobs/routes/JobsRoutes';

import ProtectedRoute from './ProtectedRoute';

const DEFAULT_ROUTE = jobsRoutes.jobs;

const AuthorizedRoutes = (
  <Route
    element={
      <ProtectedRoute>
        <DashboardView />
      </ProtectedRoute>
    }
  >
    {/* TODO: Change string literals to constants */}
    <Route path={`approvals/*`} element={<ApprovalsView />} />
    <Route path={`${jobsRoutes.jobs}/*`} element={<JobsRoutes />} />
    <Route path={`schedule/*`} element={<ScheduleView />} />
    <Route path={`operations/*`} element={<OperationsView />} />

    <Route path="/" element={<Navigate to={DEFAULT_ROUTE} />} />

    <Route path="*" element={<PageNotFoundView />} />
  </Route>
);

export default AuthorizedRoutes;
