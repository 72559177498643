import { useMutation } from '@tanstack/react-query';

import { SubmitPreWorkQuestionnaireData, JobRepository } from 'domain/repositories/IJobRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseSubmitPreWorkQuestionnaire = () => {
  const { submitPreWorkQuestionnaire } = useHookInjection<JobRepository>(JobRepository);
  const { mutateAsync } = useMutation<void, Error, SubmitPreWorkQuestionnaireData>({
    mutationFn: submitPreWorkQuestionnaire,
  });
  return {
    mutateAsync,
  };
};
