import { useId, useState } from 'react';
import { FormControl, InputLabel, Stack, StackOwnProps, useTheme } from '@mui/material';
import { RemoveOutlined as DecrementIcon, Add as IncrementIcon } from '@mui/icons-material';

import IconButton from 'targets/web/components/IconButton';

import { StyledTextInput } from './style';

export interface NumberCounterProps extends StackOwnProps {
  label?: string;
  value?: number;
  onChange?: (val: number) => void;
  min?: number;
  max?: number;
}

const NumberCounter = ({ label, value, onChange, min, max, sx }: NumberCounterProps) => {
  const [count, setCount] = useState(value || min || 0);

  const setCountField = (v: number) => {
    setCount(v);
    if (typeof onChange !== 'undefined') {
      onChange(v);
    }
  };

  if (typeof min !== 'undefined' && count < min) {
    setCountField(min);
  }

  if (typeof max !== 'undefined' && count > max) {
    setCountField(max);
  }

  const id = useId();
  const theme = useTheme();

  return (
    <Stack gap={2} sx={{ ...sx }}>
      {label && (
        <InputLabel htmlFor={id} sx={{ ...theme.typography.labelSmall }}>
          {label}
        </InputLabel>
      )}
      <FormControl>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            variant="contained"
            size="medium"
            disabled={min === count}
            onClick={() => setCountField(count - 1)}
          >
            <DecrementIcon />
          </IconButton>
          <StyledTextInput
            value={count}
            id={id}
            variant="outlined"
            onChange={(e) =>
              Number.isInteger(+e.target.value) ? setCountField(+e.target.value) : null
            }
            inputProps={{ inputMode: 'numeric' }}
          />
          <IconButton
            variant="contained"
            size="medium"
            disabled={max === count}
            onClick={() => setCountField(count + 1)}
          >
            <IncrementIcon />
          </IconButton>
        </Stack>
      </FormControl>
    </Stack>
  );
};

export default NumberCounter;
