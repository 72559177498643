import { FC } from 'react';
import { Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';
import { damageSchema } from 'api/domain/entities/documents/PreWorkQuestionnaire';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Modal from 'targets/web/components/Modal';
import { PreWorkQuestionnaireFormWrapper } from 'targets/web/modules/jobs/components/PreWorkQuestionnaire/PreWorkQuestionnaireFormWrapper';

export const PreWorkQuestionnaireDetailsSchema = z.object({
  availabilityVerified: z.boolean().refine((value) => value === true),
  walkaroundConducted: z.boolean().refine((value) => value === true),
  hangarChecked: z.boolean().refine((value) => value === true),
  includeCommunication: z.boolean().refine((value) => value === true),
  toolsUsed: z.object({
    bottles: z.number(),
    microfiber: z.number(),
    terrycloth: z.number(),
    cleanKit: z.number(),
    other: z.number(),
  }),
  damageFound: z.set(damageSchema),
  employeeCount: z.number().gte(0),
});

export type PreWorkQuestionnaireDetailsData = z.infer<typeof PreWorkQuestionnaireDetailsSchema>;

const initialValues = {
  availabilityVerified: false,
  walkaroundConducted: false,
  hangarChecked: false,
  includeCommunication: false,
  toolsUsed: {
    bottles: 5,
    microfiber: 0,
    terrycloth: 0,
    cleanKit: 0,
    other: 0,
  },
  damageFound: new Set(['interior-flight_deck-carpet']),
  employeeCount: 0,
} as PreWorkQuestionnaireDetailsData;

export interface PreWorkQuestionnaireDialogProps {
  onSubmit: (values: PreWorkQuestionnaireDetailsData) => void;
  onClose: () => void;
  open: boolean;
}

export const PreWorkQuestionnaireDialog: FC<PreWorkQuestionnaireDialogProps> = ({
  onSubmit,
  onClose,
  open,
}) => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire');
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={toFormikValidationSchema(PreWorkQuestionnaireDetailsSchema)}
    >
      {({ isValid }) => (
        <Modal
          onClose={onClose}
          open={open}
          title={t('title')}
          maxWidth="lg"
          content={<PreWorkQuestionnaireFormWrapper />}
          sx={{
            '& .MuiDialogActions-root': {
              justifyContent: 'space-between',
            },
          }}
          actions={{
            primary: {
              size: 'large',
              type: 'submit',
              startIcon: <PlayArrowIcon />,
              text: t('buttons.resume'),
              disabled: !isValid,
            },
            secondary: {
              size: 'large',
              text: t('buttons.cancel'),
            },
          }}
        />
      )}
    </Formik>
  );
};
