import { GridItemProps, VirtuosoGrid, VirtuosoGridProps } from 'react-virtuoso';
import { Box, BoxProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';

interface VirtualizedContainerProps<T> extends VirtuosoGridProps<T, unknown> {
  spacing?: number;
  minColumnWidth?: number;
}

export const VirtualizedGrid = <T,>({
  spacing,
  minColumnWidth,
  components,
  style,
  overscan,
  ...props
}: VirtualizedContainerProps<T>) => {
  const theme = useTheme();

  return (
    <VirtuosoGrid
      {...props}
      overscan={overscan ?? 300}
      style={{
        height: '100%',
        marginRight: theme.spacing(-4),
        ...style,
      }}
      components={{
        ...components,
        // eslint-disable-next-line react/display-name
        Item: forwardRef<HTMLDivElement, GridItemProps>((props, ref) => (
          <Box
            {...props}
            ref={ref}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              '& > *': { flex: 1 },
            }}
          />
        )),
        // eslint-disable-next-line react/display-name
        List: forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
          <Box
            {...props}
            ref={ref}
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth ?? 300}px, 1fr))`,
              gap: spacing,
            }}
          />
        )),
      }}
    />
  );
};
