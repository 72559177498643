import { Stack, StackOwnProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface QuestionBoxProps extends StackOwnProps {
  size?: 'small' | 'regular';
  children?: ReactNode | ReactNode[];
}

export const QuestionBox: FC<QuestionBoxProps> = ({ size = 'small', children, ...props }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      {...props}
      sx={{
        padding: size === 'small' ? 2 : 4,
        borderRadius: 1,
        backgroundColor: 'background.neutral',
        ...props.sx,
      }}
    >
      {children}
    </Stack>
  );
};
