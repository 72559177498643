import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Aircraft } from 'domain/entities/Aircraft';
import { useCaseSearchAircraft } from 'application/aircraft/useCases/useCaseSearchAircraft';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete } from 'targets/web/components';

export const AircraftAutocomplete: FC<{ onChange: (id: ID | undefined) => void }> = ({
  onChange,
}) => {
  const t = useTranslationPrefix('components.filters_container');

  const [aircraftPhrase, setAircraftPhrase] = useState('');
  const { items, isLoading } = useCaseSearchAircraft({ phrase: aircraftPhrase });

  const aircraftItems = useMemo(
    () => [{ id: ID('all'), code: 'All' } as Entity<Aircraft>].concat(items),
    [items],
  );

  const handleInputChange = useCallback(
    debounce((event: SyntheticEvent, newInputValue: string) => {
      if (event?.type !== 'change' && newInputValue) {
        return;
      }

      setAircraftPhrase(newInputValue);
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Aircraft>, false, false, false>
      label={t('aircraft_label')}
      loading={isLoading}
      options={aircraftItems}
      defaultValue={aircraftItems[0]}
      getOptionLabel={(option) => option.code}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={(_, value) => {
        onChange(value && value.id !== 'all' ? ID(value.id) : undefined);
      }}
    />
  );
};
